import React from 'react'
import s from "./FootResp.module.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const FootResp = () => {

    const handleWasap = function (e) {
        e.preventDefault();
        // isMobile ?
        // window.open(`https://api.whatsapp.com/send?phone=+5491132640285&&app_absent=0`,'_blank') : 
        window.open(`https://web.whatsapp.com/send?phone=+5491132640285`, '_blank');
    }

    const handleFacebook = function (e) {
        e.preventDefault();
        // isMobile ?
        // window.open(`https://api.whatsapp.com/send?phone=+5491132640285&&app_absent=0`,'_blank') : 
        window.open(`https://www.facebook.com/agapelibros`, '_blank');
    }

    const handleInstagram = function (e) {
        e.preventDefault();
        // isMobile ?
        // window.open(`https://api.whatsapp.com/send?phone=+5491132640285&&app_absent=0`,'_blank') : 
        window.open(`https://www.instagram.com/agapelibros/`, '_blank');
    }

    return (
        <div className={s.container}>
            <div className={s.redes}>
                <div className={s.wap} alt="WhatsApp" onClick={handleWasap}> <WhatsAppIcon sx={{ fontSize: 40 }} /> </div>
                <div className={s.fac} alt="Facebook" onClick={handleFacebook}> <FacebookIcon sx={{ fontSize: 40 }} /> </div>
                <div className={s.ing} alt="Instagram" onClick={handleInstagram}> <InstagramIcon sx={{ fontSize: 40 }} /> </div>
            </div>
            <p>
                CASA CENTRAL / Av. San Martín 6863 - Buenos Aires
            </p>
        </div>
    )
}

export default FootResp