import React from 'react';
import s from "./SectionTitle.module.css";

export default function SectionTitle({content, styles}) {
  return (
    <div className={styles ? s.sectionTitleContainerLibros : s.sectionTitleContainer}>
        <div className={s.sectionTitleWrapper}>
            <h2>{content}</h2>
        </div>
        <div/>
        <div/>
        <div className={s.sectionTitleUnderline}/>
    </div>
  )
}
