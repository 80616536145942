import React from 'react';
import s from "./InfoCompras.module.css";
import { LocalShippingOutlined, CreditCardOutlined, MenuBook} from '@mui/icons-material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

export function InfoCompras() {
  return (
    <div className={s.infoCompras}>
        <div className={s.infoComprasItem}>
            <p>Hacemos envíos a todo<br/>el país</p>
            <LocalShippingOutlined sx={{ fontSize: 35, color: "#282828" }}/>
        </div>
        <div className={s.infoComprasDivisor}/>
        <div className={s.infoComprasItem}>
            <p>Pago por transferencia <br/>o Mercadopago </p>
            <CreditCardOutlined sx={{ fontSize: 35, color: "#282828" }}/>
        </div>
        <div className={s.infoComprasDivisor}/>
        <div className={s.infoComprasItem}>
            <p>Retiro gratis <br/>por sucursal</p>
            <MenuBook sx={{ fontSize: 35, color: "#282828" }}/>
        </div>
    </div>
  )
}

export function InfoComprasCarrusel() {
  return (
    <div className={s.shippingContainer} id="top-of-page-mobile">
      <Swiper 
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
            <div className={s.shippingSwiper}>
                <p>Hacemos envíos a todo el país</p>
                <LocalShippingOutlined sx={{ fontSize: 20, color: "#fff" }} />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className={s.shippingSwiper}>
                <p>Pago por transferencia o Mercadopago </p>
                <CreditCardOutlined sx={{ fontSize: 20, color: "#fff" }}/>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className={s.shippingSwiper}>
                <p>Retiro gratis por sucursal</p>
                <MenuBook sx={{ fontSize: 20, color: "#fff" }}/>
            </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
