import { useEffect, useState, useRef } from 'react';
import s from "./Contacto.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toastSuccess, toastError } from "../../helpers/index";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import { trackPage } from '../../helpers/index';

export default function Sucursales() {
    const [validated, setValidated] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const inputRef = useRef();

    const [searchParams, setSearchParams] = useSearchParams();

    const topOfPageElement = document.getElementById("top-of-page-mobile");

    const Navigate = useNavigate();
    const token = useSelector(state => state.token);

    const handleInputChange = function(e){
        setInputs((prev)=>({...prev, [e.target.id]: e.target.value}));
    }

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        let aux = true;
        for (const [key, value] of Object.entries(inputs)) {
            if(value === "" || !value){
                aux = false;
            }
        }
        setValidated(aux);

        if(searchParams.get("checkbox") === "true") { //Si recibe url param se activa el checkbox de cv
            setIsChecked(true);
        }
    },[inputs])

    const handleSubmit = async function() {
        setLoading(true)

        const formData = new FormData();
        formData.append('archivo_csv', inputs.cv);

        try{
            await fetch(`${process.env.REACT_APP_URL_API}servicios/grabarcontacto.php?token=${token}&nombre=${inputs.nombre}&apellido=${inputs.apellido}&telefono=${inputs.telefono}&email=${inputs.email}&whatsapp=${inputs.whatsapp}&comentario=${inputs.comentario}`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                if (data.response_code == 2 || data.response_code == 1){
                    setLoading(false)
                    toastSuccess("Su consulta fue enviada");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    Navigate("/");
                }
            });
        }catch(e){
            toastError("Ocurrió un error inesperado, intentalo mas tarde");
            Navigate("/");
        }
    }

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
    }

    return (
    <div className={s.container}>
        <div className={s.formContainer}>
            <form className={s.form} onSubmit={(e)=>{e.preventDefault(); handleSubmit();}}>
                <h3>Formulario de Contacto</h3>
                <p>Complete el formulario para enviar su consulta</p>
                <div className={s.rows}>
                    <div className={s.inputContainer}>
                        <label htmlFor="nombre">Nombre</label>
                        <input id='nombre' type='text' required size="1" onChange={handleInputChange} value={inputs.nombre}/>
                    </div>
                    <div className={s.inputContainer}>
                        <label htmlFor="apellido">Apellido</label>
                        <input id='apellido' type='text' required size="1" onChange={handleInputChange} value={inputs.apellido}/>
                    </div>
                </div>
                <div className={s.rows}>
                    <div className={s.inputContainer}>
                        <label htmlFor="email">E-mail</label>
                        <input id='email' type='email' required size="1" onChange={handleInputChange} value={inputs.email}/>
                    </div>
                    <div className={s.inputContainer}>
                        <label htmlFor="telefono">Teléfono</label>
                        <input id='telefono' type='tel' required size="1" onChange={handleInputChange} value={inputs.telefono}/>
                    </div>
                    <div className={s.inputContainer}>
                        <label htmlFor="whatsapp">Whatsapp</label>
                        <input id='whatsapp' type='tel' required size="1" onChange={handleInputChange} value={inputs.whatsapp}/>
                    </div>
                </div>
                <div className={s.inputContainer}>
                    <label htmlFor="comentario">Comentario</label>
                    <input id='comentario' type='text' required size="1" onChange={handleInputChange} value={inputs.comentario}/>
                </div>
                <div className={s.checkboxContainer}>
                    <input id="trabajo" type='checkbox' checked={isChecked} onChange={handleCheckbox} />
                    <span>¿Quiere trabajar con nosotros?</span>
                </div>
                {
                    isChecked &&
                    <div className={s.inputFileContainer}>
                        <label htmlFor="cv">Adjuntar CV</label>
                        <input id="cv" type="file" accept=".doc, .docx, .pdf" onChange={() => setInputs((prev)=>({...prev, ["cv"]: inputRef.current.files[0]}))} ref={inputRef} />
                        <span>.doc, .docx y .pdf</span>
                    </div>
                }
                <div className={s.btnsContainer}>
                    <button type='submit' className={validated && !loading ? s.btnSubmit : s.btnSubmitDisabled} disabled={loading ? true : false}>
                        {
                            !loading ? 
                                "Enviar"
                            : <LoadingSpinner contactSection={true} />
                        }
                    </button>
                </div>
            </form>
        </div>
    </div>
    )
}