import React, { useState } from 'react';
import s from "./SelectDireccion.module.css";
import Direccion from "../Direccion/Direccion";
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';

export default function SelectDireccion({direcciones, nextStep, modalDireccionControl, selected, setSelected, modalSucursalControl, sucursal, inputs}) {

    // Este componente renderiza la lista de direcciones que el usuario tiene registradas. Tambien renderiza la opcion de retirar en tienda con id -1 y la opcion de agregar nueva direccion con id -2

    const Navigate = useNavigate();

    return (
        <div className={s.container}>
            <h3>¿A dónde lo enviamos?</h3>
            <div className={s.direcciones}>

            <Direccion direccion={{titulo: "Retirar en tienda", precio: "Gratis"}} index={-1} setSelected={setSelected} selected={selected === -1 ? true : false} modalSucursalControl={modalSucursalControl} sucursal={sucursal}/>

            {
                direcciones?.map((direccion, index)=>{
                    if (index != 0){
                        return <Direccion key={index} index={index} direccion={{titulo: "Enviar a otra dirección",direccion:direccion.direccion}} setSelected={setSelected} selected={selected === index ? true : false}/>
                    }
                    else {
                        return <Direccion key={index} index={index} direccion={direccion} setSelected={setSelected} selected={selected === index ? true : false}/>
                    }
                })
            }

            {
                direcciones.length < 2 &&
                <Direccion direccion={{titulo: "Enviar a otra dirección"}} index={-2} setSelected={setSelected} selected={selected === -2 ? true : false} modalDireccionControl={modalDireccionControl}/>
            }
            

            </div>
            <div className={s.btnsContainer}>
                <div className={s.btnBackContainer} onClick={()=>{nextStep('', "1", inputs);}}>
                    <ArrowBackIos sx={{ fontSize: 16 }}/>
                    <p className={s.btnBack}>Volver atrás</p>
                </div>
                <button type='submit' onClick={()=>{selected !== null && nextStep();}} className={selected !== null ? s.btnSubmit : s.btnSubmitDisabled}>Siguiente</button>
            </div>
        </div>
    )
}
