import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { getToken } from '../../redux/actions';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import s from "./Swiper.module.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {

    const dispatch = useDispatch();
    const token = useSelector(state => {return state.token});
    const [images, setImages] = useState(null);

    useEffect(() => {
        
        if(!token){  // Si no hay token, lo pedimos
            dispatch(getToken());
        } else {
            const getImages = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getcategorias.php?token=${token}&id_categoria=7`);
                    setImages(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
            getImages();
        }

    }, [token]);

    return (
        <>
        <Swiper
            style={{
                "--swiper-navigation-color": "rgba(28, 27, 31, 0.5)",
                "--swiper-navigation-size": "40px",
                "--swiper-pagination-color": "#1c1d3e"
            }}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
            delay: 7000,
            disableOnInteraction: false,
            }}
            pagination={{
            clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className={s.swiper}
        >
            {
                images && images.map(elem => {
                    return (
                        <SwiperSlide className={s.swiperSlide} key={elem.id_editor}>
                            {
                                elem.url !== "" ?
                                <a href={elem.url} target="_blank">
                                    <img alt={elem.nombre} src={`${process.env.REACT_APP_URL_API}admin/${elem.imagen}`}/>
                                </a>
                                :
                                <img alt={elem.nombre} src={`${process.env.REACT_APP_URL_API}admin/${elem.imagen}`}/>
                            }
                        </SwiperSlide>
                    )
                }
            )}
        </Swiper>
        </>
    );
}
