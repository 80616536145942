import { Close } from '@mui/icons-material';
import s from "./SucuResp.module.css";
import logo from "../../assets/agape logo secondary.png"

const SucuResp = ({ modalSucuControl, cambiarlocal, locales, capitalizeFirstLetters }) => {
    return (
        <div className={s.container}>
            <div className={s.column}>
                <div className={s.columnHeader}>
                    <Close sx={{ fontSize: 28, color: "#4F000B", cursor: "pointer" }} onClick={() => { modalSucuControl(false) }} />
                </div>
                <div className={s.columnContent}>
                    <div className={s.category}>
                        <h3>CABA</h3>
                        <ul>
                            {   
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia === "Capital Federal") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal);modalSucuControl(false) }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className={s.category}>
                        <h3>Provincia Bs. As.</h3>
                        <ul>
                            {
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia === "Buenos Aires") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal);modalSucuControl(false) }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className={s.category}>
                        <h3>Interior</h3>
                        <ul>
                            {
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia !== "Buenos Aires" && local.provincia !== "Capital Federal") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal);modalSucuControl(false) }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className={s.footerSucursales}>
                    <div>
                        <img src={logo} alt="Logo Agape"/>
                        <p>Casa Central <br/> Av. San Martín 6863 - Buenos Aires</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SucuResp