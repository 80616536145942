import * as constants from "../constants"

// El reducer guarda el estado global inicial y exporta el rootReducer. Un switch que recibe en el parametro "action" el objecto que despachamos en el archivo de actions.
// Como indicamos en el archivo actions, el objeto tiene las propiedades type y payload. El switch devuelve una copia del estado, pero con la variable modificada.

const initialState = {
    token: null,
    libros: [],
    categorias: {
        temas: [],
        colecciones: [],
        ofertas: []
    },
    cartCant: 0,
    libro: {
        id_libro: null,
        titulo: "",
        nro_coleccion: null,
        autores: "",
        imagen: "",
        precio: 0,
        preciodescuento: 0,
        editorial: "",
        isbn13: "",
        recension: "",
        coleccion: "",
        codigo: "",
        librosAutor: [],
        librosColeccion: []
    },
    navbarPosition: null
}

export default function rootReducer(state = initialState, action){

  switch (action.type) {

    case constants.GET_TOKEN:
        return {
            ...state,
            token: action.payload
        }
      
    case constants.GET_LIBROS:
        return {
            ...state,
            libros: action.payload
        }

    case constants.GET_CATEGORIAS:
        return {
            ...state,
            categorias: action.payload
        }

    case constants.GET_LIBRO:
        return {
            ...state,
            libro: action.payload
        }

    case constants.CLEAR_LIBRO:
        return {
            ...state,
            libro: action.payload
        }

    case constants.UPDATE_CART_CANT:
        return {
            ...state,
            cartCant: action.payload
        }

    case constants.SET_NAVBAR_POSITION:
        return {
            ...state,
            navbarPosition: action.payload
        }

    default:
        return state
        
  }
}
