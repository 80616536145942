import s from "./FiltrosModalResp.module.css";
import logo from "../../assets/agape logo secondary.png";
import { SelectOutlineFilter, InputOutlineSearch, InputOutlinePrice, CheckboxOutlineFilter } from "../CallToAction/CallToAction";

function FiltrosModalResp({ modalMenuRespControl, 
    categorias, handleCategorias, handleAutor, handleInputFormat, setAutorTextInput, 
    autores, handleEditorial, setEditorialTextInput, editoriales, handlePrice, setPriceTextInput, 
    isChecked, handleCheckbox, ofertas }) {

    return (
        <div className={s.container}>
            <div className={s.header}>
                <span onClick={() => { modalMenuRespControl(false) }}>
                    Aplicar
                </span>
            </div>

            <div className={s.navegador}>

                <div className={s.title_container}>
                    <p>Filtros</p>
                    <div className={s.title_underline}></div>
                </div>

                {
                    ofertas?.length > 0 &&
                    <CheckboxOutlineFilter content="Ofertas" isChecked={isChecked} handleCheckbox={handleCheckbox} />
                }
                
                <SelectOutlineFilter content="Tema" onChange={(e)=>{handleCategorias("temas", e.target.value)}} array={categorias.temas} tema={true} />

                <InputOutlineSearch content="Autor" onSubmit={handleAutor} onChange={(e)=>{handleInputFormat(e.target.value, setAutorTextInput)}} onClick={handleAutor} array={autores} autor={true} />

                <InputOutlineSearch content="Editorial" onSubmit={handleEditorial} onChange={(e)=>{handleInputFormat(e.target.value, setEditorialTextInput)}} onClick={handleCategorias} array={editoriales} />

                <SelectOutlineFilter content="Colección" onChange={(e)=>{handleCategorias("colecciones", e.target.value)}} array={categorias.colecciones} />

                <InputOutlinePrice onSubmit={handlePrice} onChange={setPriceTextInput} />

                <div className={s.separador} />

                <div className={s.footerFiltros}>
                    <div>
                        <img src={logo} alt="Logo Agape"/>
                        <p>Casa Central <br/> Av. San Martín 6863 - Buenos Aires</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltrosModalResp;