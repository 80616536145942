import React, { useState, useEffect } from 'react';
import s from "./Checkout.module.css";
import "./CheckoutModalStyles.css";
import { Stepper, Step, StepLabel } from '@mui/material';
import FormDireccion from './FormDireccion/FormDireccion';
import SelectDireccion from "./SelectDireccion/SelectDireccion";
import Cart from '../../components/Cart/Cart';
import Modal from 'react-modal';
import axios from 'axios';
import SelectSucursal from "./SelectSucursal/SelectSucursal";
import SelectPago from "./SelectPago/SelectPago";
import { CallToAction } from '../../components/CallToAction/CallToAction';
import { useNavigate } from 'react-router-dom';
import { toastError } from "../../helpers/index";
import { useSelector, useDispatch } from 'react-redux';
import { updateCart } from '../../redux/actions';
import useMediaQuery from '../../hooks/useMediaQuery';
import { trackPage } from '../../helpers/index';

// Configuracion del modal
Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
    overlay: {
        zIndex: 100,
        background: "#00000060",
        height: "100vh",
        width: "100vw",
        margin: "0",
    }
};

const stepperStyles = {
    '& .MuiStepLabel-root .Mui-completed': {
      color: '#1c1d3e' // circle color (COMPLETED)
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: '#1c1d3e' // circle color (ACTIVE)
    },
    '.MuiStepLabel-labelContainer span': {
        'fontFamily': 'Barlow'
    }
}

export default function Checkout() {
    const [activeStep, setActiveStep] = useState(0);  // Paso actual del stepper.
    const [activeFacturacionStep, setActiveFacturacionStep] = useState(0); // Paso intermedio de facturacion y envio, son 2 pasos dentro del paso 1.
    const [direcciones, setDirecciones] = useState([]); // Array de objetos con las direcciones ingresadas.
    const [selectedDireccion, setSelectedDireccion] = useState(null);  // Indice de la direccion seleccionada. -1 es Retirar en tienda y -2 es nueva direccion.
    const [totalCantidades, setTotalCantidades] = useState(0);  // Cantidad de libros. Requerida en la query de calculo de costo de envio.
    const [totalCarrito, setTotalCarrito] = useState(0);  // Costo total del carrito sin envio.
    const [pedidoGuardado, setPedidoGuardado] = useState(false);  // Costo total del carrito sin envio.
    const [productosCarrito, setProductosCarrito] = useState([]);
    const [sucursal, setSucursal] = useState({sucursal: "", id_sucursal: null});  // Sucursal seleccionada para retirar.
    const [loading, setLoading] = useState(false);
    const [numeroPedido, setNumeroPedido] = useState(0);
    const [tipoCarrito, setTipoCarrito] = useState("checkoutEditable");

    const [modalDireccion, setModalDireccion] = useState(false);  // Control del modal de nueva direccion.
    const [modalSucursal, setModalSucursal] = useState(false);  // Control del modal de seleccionar sucursal.
    const [modalTransferencia, setModalTransferencia] = useState(false);  // Control del modal de transferencia.
    
    const [formDireccionInputs, setFormDireccionInputs] = useState({ // Inputs del form de nueva direccion.
        nombre: "",
        apellido: "",
        dni: "",
        telefono: "",
        email: "",
        calle: "",
        altura: "",
        piso: "",
        barrio: "",
        provincia: "",
        cp: "",
        alias: "",
        localidad: ""
    });

    const topOfPageElement = document.getElementById("top-of-page-mobile");
    const isMobile = useMediaQuery('(max-width: 550px)');

    const token = useSelector(state => state.token);
    const Navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{  // Incialmente consultamos el localStorage y nos traemos la data del carrito

        let productsCart = [];
        productsCart = localStorage.getItem('order');
        productsCart = JSON.parse(productsCart);

        if(!productsCart.length){
            Navigate('/');
        }

        let cantidades = 0;
        let total = 0;
        for(let i = 0; i < productsCart.length; i++){
            cantidades = cantidades + (Number(productsCart[i].cant));
            total = total + (Number(productsCart[i].preciodescuento) * Number(productsCart[i].cant));
        }
        setTotalCarrito(total);
        setTotalCantidades(cantidades);
        setProductosCarrito(productsCart);

    },[])
    
    const nextStep = async function(pago, backBtn, inputs, paymentType){
        if(topOfPageElement) { //Scrolleamos hacia arriba en mobile
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }
        if (backBtn){
            if (backBtn == '1'){
                setActiveFacturacionStep(0);
                setDirecciones([]);
                setSelectedDireccion(null);
                setFormDireccionInputs(inputs);
                setTipoCarrito("checkoutEditable");
                return;
            }
            if (backBtn == '2'){
                setActiveStep(0);
            }
        }
        // Setea los pasos del stepper (la barra de progreso del checkout) y hace cosas para cada caso. activeStep tambien renderiza distintos componentes dependiendo su valor.
        if(activeStep === 0 && activeFacturacionStep === 0){
            // El primero paso tiene un sub-paso, por ende si activeStep es 0, lo dejamos en 0 pero ponemos en 1 el subpaso
            setActiveFacturacionStep(1);
            newAdress();
            setTipoCarrito("checkout");
            // if (direcciones[selectedDireccion]){
            //     setTotalEnvio(direcciones[selectedDireccion].precio);
            // } else {
            //     setTotalEnvio(0);
            // }

        } else if(activeStep === 0 && activeFacturacionStep === 1){
            // Si activeStep es 0, lo dejamos en 0 pero ponemos en 1 el subpaso
            guardarPedido();
            setActiveStep(1);

        } else if (activeStep < 2) {
            if(pago === 'transferencia'){
                setActiveStep(2);
                handleTransferencia(paymentType);
            }
        }
    }
    
    const newAdress = async function(){ // Guarda una nueva direccion en el estado de direcciones
        let promise;

        setSelectedDireccion(direcciones.length) // indice de direccion, el indice deberia ser length-1, pero todavia no pusheamos asi que es -1+1 = 0

        try{
            promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getCostoEnvio.php?token=${token}&id_provincia=${formDireccionInputs.provincia}&totalcantidades=${totalCantidades}`);
        } catch(e){
            console.log(e);
        }

        setDirecciones((prev)=> ([...prev, {...formDireccionInputs,
            titulo: "Enviar a mi domicilio",
            precio: promise.data[0] ? promise.data[0].monto : "A convenir",
            direccion: `${formDireccionInputs.calle} ${formDireccionInputs.altura}, ${formDireccionInputs.piso}. ${formDireccionInputs.barrio}, CP ${formDireccionInputs.cp}`
        }]))

        // setFormDireccionInputs((prev)=> ({...prev, calle: "", altura: "", piso: "", barrio: "", provincia: "", cp: ""}));
        modalDireccionControl(false);
    }

    const handleSucursal = function(suc){  // Se ejecuta cuando se selecciona una sucursal mediante el componente SelectSucursal
        setSucursal(suc);
        modalSucursalControl(false);
        setSelectedDireccion(-1);
    }

    const modalDireccionControl = function(bool){
        setModalDireccion(bool);
    }

    const modalSucursalControl = function(bool){
        setModalSucursal(bool);
    }

    const modalTransferenciaControl = function(bool){
        setModalTransferencia(bool);
    }

    //generamos la query con toda informacion del usuario para registrar su pedido en la base de datos.
    const guardarPedido = async function(){ 
        setLoading(true);

        let ip = "";
        try{    
            ip = await axios.get("https://geolocation-db.com/json/");
            ip = ip.data.IPv4
        }catch(e){
            ip = "";
        }

        let librosString = "";

        for(let i = 0; i < productosCarrito.length; i++){
            librosString = librosString + `${productosCarrito[i].codigo};${productosCarrito[i].cant};${productosCarrito[i].preciodescuento}`;

            if((productosCarrito.length - 1 ) !== i){
                librosString = librosString + "|";
            }
        }

        if(librosString === "" || !librosString){
            toastError("No se detectaron productos en su carrito.");
            Navigate("/");
            return;
        }

        let body;

        //Body con condicionales si retira en tienda o si se envia a su domicilio.
        body = {
            nombre: formDireccionInputs.nombre,
            apellido: formDireccionInputs.apellido,
            dni: formDireccionInputs.dni,
            direccion: `${direcciones[0].calle} ${direcciones[0].altura}`,
            direccion_otra: direcciones[1] ? `${direcciones[1].calle} ${direcciones[1].altura}` : '',
            localidad: direcciones[0].localidad,
            localidad_otra: direcciones[1] ? direcciones[1].localidad : '',
            cp: direcciones[0].cp,
            cp_otra: direcciones[1] ? direcciones[1].cp : '',
            id_provincia: direcciones[0].provincia,
            id_provincia_otra: direcciones[1] ? direcciones[1].provincia : '',
            telefono: formDireccionInputs.telefono,
            email: formDireccionInputs.email,
            comentario: formDireccionInputs.alias,
            subtotal: totalCarrito,
            costoenvio: selectedDireccion !== -1 ? direcciones[selectedDireccion].precio : 0,
            id_provincia_envio: selectedDireccion !== -1 ? direcciones[selectedDireccion].provincia : 0,
            ip: ip,
            id_sucursal: selectedDireccion !== -1 ? 0 : sucursal.id_sucursal,
            piso_depto: direcciones[0].piso,
            piso_depto_otra: direcciones[1] ? direcciones[1].piso : '',
            barrio: direcciones[0].barrio,
            barrio_otra: direcciones[1] ? direcciones[1].barrio : '',
            libros: librosString,
            alias: formDireccionInputs.alias
        }

        let postUrl;

        if (numeroPedido === 0) {
            postUrl = `${process.env.REACT_APP_URL_API}servicios/grabarpedido.php?token=${token}&nropedidousar=`
        } else {
            postUrl = `${process.env.REACT_APP_URL_API}servicios/grabarpedido.php?token=${token}&nropedidousar=${numeroPedido}`
        }

        try{
            await fetch(postUrl, {
                method: 'POST',
                body: JSON.stringify(body)
            })
            .then(response => response.json())
            .then(data => {
                
                numeroPedido === 0 && setNumeroPedido(Number(data.response_code));

                if (selectedDireccion > -1) {
                    let envio = {
                        totalEnvio:direcciones[selectedDireccion].precio,
                        cp:direcciones[selectedDireccion].cp
                    };
                    localStorage.setItem("envio", JSON.stringify(envio));
                    localStorage.setItem("products", JSON.stringify(productosCarrito));
                } else {
                    let envio = {
                        totalEnvio:"0",
                        cp:" ",
                        sucursal:sucursal.id_sucursal
                    };
                    localStorage.setItem("envio", JSON.stringify(envio));
                    localStorage.setItem("products", JSON.stringify(productosCarrito));
                }
                setPedidoGuardado(true);
                setLoading(false);
            });
        }catch(e){
            toastError("Ocurrió un error inesperado, intentalo mas tarde");
            Navigate("/");
        }
    }

    const handleTransferencia = async function(paymentType){    //Enviamos payment type como modalidad para que se envíe el correcto mail desde backend
        // llamada al servicio de transferencia (con nro pedido)
        try{
            fetch(`${process.env.REACT_APP_URL_API}servicios/recibirpedido.php?token=${token}&nroPedido=${numeroPedido}&modalidad=${paymentType}`, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(data => {
                modalTransferenciaControl(true);
            });
        }catch(e){
            toastError("Ocurrió un error inesperado, intentalo mas tarde");
            Navigate("/");
        }
    }

    useEffect(()=>{  // Desactiva los scrolls cuando se abre un modal. Los activa al cerrar el modal.

        window.scrollTo({top:0, behavior:"smooth"});
        if(topOfPageElement) { //Scrolleamos hacia arriba en mobile
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if(modalDireccion || modalSucursal || modalTransferencia || loading){
            document.documentElement.style.overflowY = 'hidden';
            document.documentElement.style.overflowX = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'unset';
            document.documentElement.style.overflowX = 'hidden';
        }
    },[modalDireccion, modalSucursal, modalTransferencia, loading, topOfPageElement]);

    const handleCheckoutEnd = function(e){  // Termina el proceso de checkout
        e.preventDefault();

        localStorage.setItem('order', JSON.stringify([]));
        dispatch(updateCart());
        document.documentElement.style.overflowY = 'unset';
        document.documentElement.style.overflowX = 'hidden';
        Navigate("/");
    }

    return (
        <div className={s.container}>

            <div className={s.stepperContainer}>
                <Stepper activeStep={activeStep}>
                    <Step sx={stepperStyles}>
                        <StepLabel>
                            {
                                isMobile && activeStep === 0 || !isMobile ?
                                "Facturación y Envío"
                                :
                                ""
                            }
                        </StepLabel>
                    </Step>
                    <Step sx={stepperStyles}>
                        <StepLabel>
                            {
                                isMobile && activeStep === 1 || !isMobile ?
                                "Medio de Pago"
                                :
                                ""
                            }
                        </StepLabel>
                    </Step>
                    <Step sx={stepperStyles}>
                        <StepLabel>
                            {
                                isMobile & activeStep === 2 || !isMobile ?
                                "Confirmación"
                                :
                                ""
                            }
                        </StepLabel>
                    </Step>
                </Stepper>
            </div>

            <div className={s.formContainer}>
                {
                    activeStep === 0 &&
                    (
                    activeFacturacionStep === 0 ?
                        <FormDireccion nextStep={nextStep} inputs={formDireccionInputs} setInputs={setFormDireccionInputs} type="regular"/> :
                        <SelectDireccion direcciones={direcciones} nextStep={nextStep} modalDireccionControl={modalDireccionControl} modalSucursalControl={modalSucursalControl} selected={selectedDireccion} setSelected={setSelectedDireccion} sucursal={sucursal} inputs={formDireccionInputs}/>
                    )
                }
                {
                    (activeStep === 1 && pedidoGuardado) && 
                    <SelectPago nextStep={nextStep} totalEnvio={direcciones[selectedDireccion]?direcciones[selectedDireccion].precio:"0"} numeroPedido={numeroPedido}/>
                }
                <div className={s.resumenContainer}>
                    <Cart type={tipoCarrito} totalEnvio={direcciones[selectedDireccion]?.precio} cp={direcciones[selectedDireccion]?.cp}/>
                </div>
            </div>

            {/* Modal de form de nueva direccion */}
            <Modal
                isOpen={modalDireccion}
                onRequestClose={()=>{modalDireccionControl(false)}}
                style={modalStyles}
                ariaHideApp={false}
                className="modalDireccionContainer"
            >
                <div className={s.modalContainer}>
                    <FormDireccion nextStep={false} inputs={formDireccionInputs} setInputs={setFormDireccionInputs} type="modal" newAdress={newAdress} />
                </div>
            </Modal>

            {/* Modal de seleccionar sucursal */}
            <Modal
                isOpen={modalSucursal}
                onRequestClose={()=>{modalSucursalControl(false)}}
                style={modalStyles}
                ariaHideApp={false}
                className="modalSucursalContainer"
            >
                <div className={s.modalContainer}>
                    <SelectSucursal handleSucursal={handleSucursal}/>
                </div>
            </Modal>

            {/* Modal de succes en transferencia */}
            <Modal
                isOpen={modalTransferencia}
                onRequestClose={()=>{}}
                style={modalStyles}
                ariaHideApp={false}
                className="modalTransferenciaContainer"
            >
                <div className={s.modalContainer}>
                    <div className={s.modalTransferencia}>
                        <h3>¡Gracias por su pedido!</h3>
                        <p className={s.modalDesc}>Le hemos enviado un correo a su casilla con la información de su pedido. En breve nos comunicaremos con usted para coordinar el pago. Muchas gracias.</p>
                        <div className={s.callToActionWrapper}>
                            <CallToAction content="Cerrar" onClick={handleCheckoutEnd}/>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Modal de transferencia en proceso */}
            <Modal
                isOpen={loading}
                onRequestClose={()=>{}}
                style={modalStyles}
                ariaHideApp={false}
                className="modalLoadingContainer"
            >
                <div className={s.modalContainer}>
                    <div className={s.modalLoading}>
                        <p>Cargando...</p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
