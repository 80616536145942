import React from 'react';
import s from "./Direccion.module.css";
import { formatNumber } from "../../../helpers/index"

export default function Direccion({direccion, selected, setSelected, index, modalDireccionControl, modalSucursalControl, sucursal}){

    // Modulo con informacion de una direccion, tambien funciona como opcion de retirar en tienda y de nuevo domicilio

    const handleCheckbox = function(){
        if(index === -2){
            modalDireccionControl(true);
        } else if(index === -1){
            modalSucursalControl(true);
        }
        else {
            setSelected(index)
        }

    }

    return(
        <div className={s.envioContainer} style={{border: selected ? "3px solid #4d92cf" : "1px solid #C4C4C4"}}>
            <div className={s.envioHeader}>
                <div>
                    <input type='checkbox' onClick={()=>{handleCheckbox();}} checked={selected} readOnly/>
                    <p>{direccion.titulo}</p>
                </div>
                <p>
                    {
                        direccion.precio ?
                        `${direccion.precio === "Gratis" ? "Gratis" : formatNumber(direccion.precio)}` 
                        :
                        null
                    }
                </p>
            </div>
            {
                direccion.direccion &&
                <div className={s.direccionContainer}>
                    <ul>
                        <li><p className={s.direccion}>{direccion.direccion}</p></li>
                    </ul>
                    {/* <p className={s.verDirecciones}>Ver todas mis direcciones</p> */}
                </div>
            }
            {
                (sucursal && sucursal.sucursal !== "") &&
                <div className={s.direccionContainer}>
                    <ul>
                        <li><p className={s.direccion}>{`${sucursal.sucursal} - ${sucursal.direccion}`}</p></li>
                    </ul>
                </div>
            }
        </div>
    )
}