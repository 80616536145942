import React, { useState, useEffect, useRef } from 'react';
import s from "./Navbar.module.css";
import "./NavbarModalStyles.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { Search, ShoppingCart } from '@mui/icons-material';
import agapeLogo from "../../assets/agape logo secondary.png";
import Modal from 'react-modal';
import Cart from '../Cart/Cart';
import MenResp from '../MenResp/MenResp';
import { useSelector, useDispatch, connect } from 'react-redux';
import { updateCart, getToken, getCategorias, setNavbarPosition } from "../../redux/actions";
import axios from 'axios';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { capitalizeWords } from '../../helpers';
import useMediaQuery from "../../hooks/useMediaQuery";
import {InfoComprasCarrusel} from '../InfoCompras/InfoCompras';

Modal.setAppElement(document.getElementById('root'));

function Navbar() {

    // Navbar. Renderiza tambien un modal con el carrito.

    const [selected, setSelected] = useState(window.location.href.split("/")[3] || "Inicio");
    const [modalCart, setModalCart] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [libros, setLibros] = useState();
    const [editoriales, setEditoriales] = useState();
    const [autores, setAutores] = useState();
    const [menuResp, setMenuresp] = useState(false);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const categorias = useSelector(state => state.categorias.temas);
    const location = useLocation();
    const [outsideClick, setOutsideClick] = useState(false);
    const searchRef = useRef(null);

    const cartCant = useSelector(state => state.cartCant);
    const topNavInitial = useSelector(state => state.navbarPosition);

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isSmallScreen = useMediaQuery('(max-width: 415px)');
    //Variables para detectar scroll en mobile
    let topNav = document.getElementById("navbar-mobile"); 
    let topNavOffset = topNav?.offsetTop;

    const testingEnvironment = process.env.REACT_APP_ENVIRONMENT === "test";
    
    if(topNavInitial === null && topNavOffset) {
        //Guardamos la posicion inicial del navbar en redux
        dispatch(setNavbarPosition(topNavOffset));
    }

    const modalStyles = {
        overlay: {
            zIndex: 100,
            background: "#00000060",
            height: "100vh",
            width: !isSmallScreen ? "120vw" : "135vw",
            marginLeft: "-20vw"
        }
    };

    const modalControl = function (bool) {
        setModalCart(bool);
    }

    const modalMenuRespControl = function (bool) {
        setMenuresp(bool);
    }

    useEffect(() => {
        dispatch(getCategorias());

        if (searchInput && searchInput.length > 2) {
            handleLibros();
            handleEditorial();
            handleAutores();
        } else {
            setLibros([]);
            setEditoriales([]);
            setAutores([]);
        }
    }, [searchInput])

    useEffect(() => {
        dispatch(updateCart());

        if (!token) {
            dispatch(getToken());
        }
    }, [])

    useEffect(() => {

        if (modalCart, menuResp) {
            document.documentElement.style.overflowY = 'hidden';
            document.documentElement.style.overflowX = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'unset';
            document.documentElement.style.overflowX = 'hidden';
        }

        //Controlar click fuera del search para cerrar los resultados desplegados
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setOutsideClick(true);
            } else {
                setOutsideClick(false);
            }
        }
        document.addEventListener('click', handleClickOutside);

        //Manejamos el scroll en mobile para hacer el navbar fixed
        let lastScrollTop = 0;
        function scrollHandler() {
            if(isMobile) {
                //Comparamos la posicion inicial del navbar con su actual para que cuando haya redirecciones tenga su posicion inicial
                if(topNavOffset !== topNavInitial){
                    topNavOffset = topNavInitial;
                } 
                if (window.scrollY === 0 && topNavOffset == null) {
                    topNav.classList.remove(s["navegador-onScroll"]);
                    topNav.style.top='';
                } else if (window.scrollY >= topNavOffset) {
                    topNav.classList.add(s["navegador-onScroll"]);
                    if(window.scrollY > lastScrollTop){
                        topNav.style.top='-80px';
                    } else {
                        topNav.style.top='0';
                    }
                    lastScrollTop = window.scrollY;
                } else {
                    topNav.classList.remove(s["navegador-onScroll"]);
                    topNav.style.top='';
                }
            } 
            return;
        }
        window.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', scrollHandler);
        };

    }, [modalCart, menuResp, topNavOffset]);

    useEffect(() => {
        if(location.pathname === "/") {
            setSelected("Inicio");
        } else {
            setSelected(location.pathname.substring(1));
        }
    }, [location.pathname]);

    const handleLibros = async function () {

        // fetchData hace la query que recibe por parametro como string. Cada modificaion a los filtros concatena nuevos parametros en el string de la query.
        // Por ejemplo: Si filtros tiene seteado id_tema: 22 y filtro_autor: papa, el string que reciba query sera:
        // https://agape-libros.com.ar/2023/servicios/getlibros.php?token=${token}&limite_inferior=0&limite_superior=30&id_tema=22&filtro_autor=papa
        setLibros([]);
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=0&limite_superior=30&filtro_titulo=${searchInput}`);
        let response = promise.data;

        if (!response || !Array.isArray(response)) {
            setLibros([]);
        } else {
            setLibros(response);
        }
    }

    const handleEditorial = async function () {
        setEditoriales([]);
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/geteditoriales.php?token=${token}&cadena=${searchInput}`);
        let response = promise.data;

        if (!response || !Array.isArray(response)) {
            setEditoriales([]);
        } else {
            setEditoriales(response);
        }
    }

    const handleAutores = async function () {
        setAutores([]);
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getautores.php?token=${token}&cadena=${searchInput}`);
        let response = promise.data;

        if (!response || !Array.isArray(response)) {
            setAutores([]);
        } else {
            setAutores(response);
        }
    }

    const handleBtns = function (e) {
        setSelected(e.target.id);

        if (e.target.id === "Inicio") {
            Navigate('/');
        } else {
            Navigate(`/${e.target.id}`);
        }
    }

    const handleSearch = function (e, type, tituloBusqueda) {
        e.preventDefault();

        if (!searchInput || searchInput.length < 3) return;
        setLibros([]);
        setEditoriales([]);
        setAutores([]);
        let categoria = categorias?.find(elem => elem.tema.toLowerCase() === searchInput.toLowerCase());

        if (tituloBusqueda && type === "libro") {
            Navigate(`/Libros?search=${tituloBusqueda.replace(/Ã/g, 'ñ')}`); //Reemplazamos las ñ para que no se rompa la búsqueda
        } else if (tituloBusqueda && type === "editorial") {
            Navigate(`/Libros?editorial=${tituloBusqueda.replace(/Ã/g, 'ñ')}`);
        } else if (tituloBusqueda && type === "autor") {
            Navigate(`/Libros?autor=${tituloBusqueda.replace(/Ã/g, 'ñ')}`);
        } else if (categoria) {
            Navigate(`/Libros?tema=${categoria.id_tema}`);
        } else {
            Navigate(`/Libros?search=${searchInput}`);
        }
    }

    const handleInputFormat = function (search) {
        // Elimina caracteres especiales y números del valor del input
        const cleanedValue = search.replace(/[^a-zA-Z0-9áéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
        setSearchInput(cleanedValue);
    }

    const handleRedirection = () => {
        if (location.pathname === '/confirmacionMP') {
            localStorage.removeItem('products');
            localStorage.removeItem('envio');
            dispatch(updateCart());
            Navigate("/");
        } else {
            Navigate("/");
        }
    }

    return (
        <>

            {
                !isMobile &&
                <header className={s.container}>
                    <div className={s.buscador} id="top-of-page-desktop" style={{backgroundColor: testingEnvironment ? "#008000" : "#1c1d3e"}}>
                        <div className={s.layoutDiv} />

                        <div className={s.inputWrapper} ref={searchRef}>
                            <Search sx={{ fontSize: 20, color: "rgba(60, 60, 67, 0.6)", cursor: "pointer" }} onClick={handleSearch} />
                            <form onSubmit={handleSearch}>
                                <input type='text' placeholder='BUSCAR' onChange={(e) => { handleInputFormat(e.target.value) }} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                {
                                    !outsideClick &&
                                    <ul>
                                        {
                                            libros?.length > 5 ?
                                                libros?.map((libro, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "libro", libro.titulo) }}>{capitalizeWords(libro.titulo)}</li>
                                                    }
                                                }) :
                                                libros?.map((libro, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(libro.titulo); handleSearch(e, "libro", libro.titulo) }}>{capitalizeWords(libro.titulo)}</li>
                                                })
                                        }
                                        {
                                            editoriales?.length > 5 ?
                                                editoriales?.map((editorial, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "editorial", editorial.editorial) }}>{editorial.editorial}</li>
                                                    }
                                                }) :
                                                editoriales?.map((editorial, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(editorial.editorial); handleSearch(e, "editorial", editorial.editorial) }}>{editorial.editorial}</li>
                                                })
                                        }
                                        {
                                            autores?.length > 5 ?
                                                autores?.map((autor, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "autor", autor.autor) }}>{capitalizeWords(autor.autor)}</li>
                                                    }
                                                }) :
                                                autores?.map((autor, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(autor.autor); handleSearch(e, "autor", autor.autor) }}>{capitalizeWords(autor.autor)}</li>
                                                })
                                        }
                                    </ul>
                                }
                            </form>
                        </div>

                        <div className={s.buscadorBtns}>
                            <div className={s.buscadorBtn}
                                onClick={() => { location.pathname !== '/confirmacionMP' && location.pathname !== '/Checkout' && modalControl(true); }}>
                                {
                                    cartCant > 0 && location.pathname !== '/confirmacionMP' &&
                                    <div className={s.cartBubble}><p>{cartCant}</p></div>
                                }
                                <ShoppingCart sx={{ fontSize: 26, color: "#ffffff" }} />
                                <p>Mi Carrito</p>
                            </div> 
                        </div>
                    </div>
                    <div className={s.navegador} translate="no">
                        <div className={s.layoutDivResp} />
                        <img alt="Agape Logo" src={agapeLogo} onClick={() => { setSelected("Inicio"); handleRedirection(); }} />
                        <nav>
                            <ul>
                                <li>
                                    <p id="Inicio" style={{ scale: selected === "Inicio" && "1.1" }} onClick={(e) => { handleBtns(e) }}>INICIO</p>
                                    <div className={s.underline} style={{ display: selected !== "Inicio" && "none" }} />
                                </li>
                                <li>
                                    <p id="Libros" style={{ scale: selected === "Libros" && "1.1" }} onClick={(e) => { handleBtns(e) }}>LIBROS</p>
                                    <div className={s.underline} style={{ display: selected !== "Libros" && "none" }} />
                                </li>
                                {
                                    testingEnvironment &&
                                    <li>
                                        <p id="Santeria">SANTERIA</p>
                                    </li>
                                }
                                <li>
                                    <p id="Sucursales" style={{ scale: selected === "Sucursales" && "1.1" }} onClick={(e) => { handleBtns(e) }}>SUCURSALES</p>
                                    <div className={s.underline} style={{ display: selected !== "Sucursales" && "none" }} />
                                </li>
                                <li>
                                    <p id="Contacto" style={{ scale: selected === "Contacto" && "1.1" }} onClick={(e) => { handleBtns(e) }}>CONTACTO</p>
                                    <div className={s.underline} style={{ display: selected !== "Contacto" && "none" }} />
                                </li>
                                {/* <li>
                            <p id="Santeria" style={{scale: selected === "Santeria" && "1.2"}} onClick={(e)=>{handleBtns(e)}}>SANTERÍA</p>
                            <div className={s.underline} style={{display: selected !== "Santeria" && "none"}}/>
                        </li> */}
                            </ul>
                        </nav>
                        <div className={s.iconosResp}>
                            <div onClick={() => { modalControl(true); }}><ShoppingCartOutlinedIcon sx={{ color: "#4F000B" }} /></div>
                            <div onClick={() => { modalMenuRespControl(true); }}>
                                <MenuOutlinedIcon sx={{ color: "#4F000B" }} />
                                <container className={s.menure}>
                                    <ul>
                                        <li>
                                            <p id="Inicio" style={{ scale: selected === "Inicio" && "1.2" }} onClick={(e) => { handleBtns(e) }}>INICIO</p>
                                            <div className={s.underline} style={{ display: selected !== "Inicio" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Libros" style={{ scale: selected === "Libros" && "1.2" }} onClick={(e) => { handleBtns(e) }}>LIBROS</p>
                                            <div className={s.underline} style={{ display: selected !== "Libros" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Sucursales" style={{ scale: selected === "Sucursales" && "1.2" }} onClick={(e) => { handleBtns(e) }}>SUCURSALES</p>
                                            <div className={s.underline} style={{ display: selected !== "Sucursales" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Contacto" style={{ scale: selected === "Contacto" && "1.2" }} onClick={(e) => { handleBtns(e) }}>CONTACTO</p>
                                            <div className={s.underline} style={{ display: selected !== "Contacto" && "none" }} />
                                        </li>
                                    </ul>
                                </container>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isOpen={modalCart}
                        onRequestClose={() => { modalControl(false) }}
                        style={modalStyles}
                        ariaHideApp={false}
                        className="modalCartContainer"
                    >
                        <Cart modalControl={modalControl} type="navbar" />
                    </Modal>
                    <Modal
                        isOpen={menuResp}
                        onRequestClose={() => { modalMenuRespControl(false) }}
                        style={modalStyles}
                        ariaHideApp={false}
                        className="modalCartContainer"
                    >
                        <MenResp modalMenuRespControl={modalMenuRespControl} />
                    </Modal>
                </header>
            }

           {/*  Navbar Mobile */}
            {
                isMobile &&
                <>
                    <InfoComprasCarrusel />

                    <div className={s.navegador} id="navbar-mobile">
                        <div className={s.layoutDivResp} />
                        <img alt="Agape Logo" src={agapeLogo} onClick={() => { setSelected("Inicio"); handleRedirection(); }} />
                        <nav>
                            <ul>
                                <li>
                                    <p id="Inicio" style={{ scale: selected === "Inicio" && "1.2" }} onClick={(e) => { handleBtns(e) }}>INICIO</p>
                                    <div className={s.underline} style={{ display: selected !== "Inicio" && "none" }} />
                                </li>
                                <li>
                                    <p id="Libros" style={{ scale: selected === "Libros" && "1.2" }} onClick={(e) => { handleBtns(e) }}>LIBROS</p>
                                    <div className={s.underline} style={{ display: selected !== "Libros" && "none" }} />
                                </li>
                                <li>
                                    <p id="Sucursales" style={{ scale: selected === "Sucursales" && "1.2" }} onClick={(e) => { handleBtns(e) }}>SUCURSALES</p>
                                    <div className={s.underline} style={{ display: selected !== "Sucursales" && "none" }} />
                                </li>
                                <li>
                                    <p id="Contacto" style={{ scale: selected === "Contacto" && "1.2" }} onClick={(e) => { handleBtns(e) }}>CONTACTO</p>
                                    <div className={s.underline} style={{ display: selected !== "Contacto" && "none" }} />
                                </li>
                            </ul>
                        </nav>
                        <div className={s.iconosResp}>
                            <div className={s.buscadorBtn} 
                                onClick={() => { location.pathname !== '/confirmacionMP' && location.pathname !== '/Checkout' && modalControl(true);}}>
                                {
                                    cartCant > 0 && location.pathname !== '/confirmacionMP' &&
                                    <div className={s.cartBubble}><p>{cartCant}</p></div>
                                }
                                <ShoppingCartOutlinedIcon sx={{ color: "#1c1d3e" }} />
                            </div>
                            <div onClick={() => { modalMenuRespControl(true); }}>
                                <MenuOutlinedIcon sx={{ color: "#1c1d3e" }} />
                                <container className={s.menure}>
                                    <ul>
                                        <li>
                                            <p id="Inicio" style={{ scale: selected === "Inicio" && "1.2" }} onClick={(e) => { handleBtns(e) }}>INICIO</p>
                                            <div className={s.underline} style={{ display: selected !== "Inicio" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Libros" style={{ scale: selected === "Libros" && "1.2" }} onClick={(e) => { handleBtns(e) }}>LIBROS</p>
                                            <div className={s.underline} style={{ display: selected !== "Libros" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Sucursales" style={{ scale: selected === "Sucursales" && "1.2" }} onClick={(e) => { handleBtns(e) }}>SUCURSALES</p>
                                            <div className={s.underline} style={{ display: selected !== "Sucursales" && "none" }} />
                                        </li>
                                        <li>
                                            <p id="Contacto" style={{ scale: selected === "Contacto" && "1.2" }} onClick={(e) => { handleBtns(e) }}>CONTACTO</p>
                                            <div className={s.underline} style={{ display: selected !== "Contacto" && "none" }} />
                                        </li>
                                    </ul>
                                </container>
                            </div>
                        </div>
                        <Modal
                            isOpen={modalCart}
                            onRequestClose={() => { modalControl(false) }}
                            style={modalStyles}
                            ariaHideApp={false}
                            className="modalCartContainer"
                        >
                            <Cart modalControl={modalControl} type="navbar" />
                        </Modal>
                        <Modal
                            isOpen={menuResp}
                            onRequestClose={() => { modalMenuRespControl(false) }}
                            style={modalStyles}
                            ariaHideApp={false}
                            className="modalCartContainer"
                        >
                            <MenResp modalMenuRespControl={modalMenuRespControl} />
                        </Modal>
                    </div>

                    <div className={s.containerInputMobile}>
                        <div className={s.inputWrapperMobile} ref={searchRef}>
                            <Search sx={{ fontSize: 20, color: "rgba(60, 60, 67, 0.6)", cursor: "pointer" }} onClick={handleSearch} />
                            <form onSubmit={handleSearch}>
                                <input type='text' placeholder='BUSCAR' onChange={(e) => { handleInputFormat(e.target.value) }} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                {
                                    !outsideClick &&
                                    <ul>
                                        {
                                            libros?.length > 5 ?
                                                libros?.map((libro, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "libro", libro.titulo) }}>{capitalizeWords(libro.titulo)}</li>
                                                    }
                                                }) :
                                                libros?.map((libro, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(libro.titulo); handleSearch(e, "libro", libro.titulo) }}>{capitalizeWords(libro.titulo)}</li>
                                                })
                                        }
                                        {
                                            editoriales?.length > 5 ?
                                                editoriales?.map((editorial, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "editorial", editorial.editorial) }}>{editorial.editorial}</li>
                                                    }
                                                }) :
                                                editoriales?.map((editorial, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(editorial.editorial); handleSearch(e, "editorial", editorial.editorial) }}>{editorial.editorial}</li>
                                                })
                                        }
                                        {
                                            autores?.length > 5 ?
                                                autores?.map((autor, index) => {
                                                    if (index < 5) {
                                                        return <li key={index} onClick={(e) => { handleSearch(e, "autor", autor.autor) }}>{capitalizeWords(autor.autor)}</li>
                                                    }
                                                }) :
                                                autores?.map((autor, index) => {
                                                    return <li key={index} onClick={(e) => { setSearchInput(autor.autor); handleSearch(e, "autor", autor.autor) }}>{capitalizeWords(autor.autor)}</li>
                                                })
                                        }
                                    </ul>
                                }
                            </form>
                        </div>
                    </div>
                </>
            }

        </>

    )
}

export default connect(null, { updateCart })(Navbar);
