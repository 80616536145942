import { useEffect, useState } from "react";
import s from "./ConfirmacionMP.module.css";
import Cart from '../../components/Cart/Cart';
import { useNavigate } from 'react-router-dom';
import { CallToAction } from '../../components/CallToAction/CallToAction';
import { Stepper, Step, StepLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getToken, updateCart } from '../../redux/actions';
import axios from "axios";
import useMediaQuery from "../../hooks/useMediaQuery";
import { trackPage } from "../../helpers";

const stepperStyles = {
    '& .MuiStepLabel-root .Mui-completed': {
      color: '#1c1d3e' // circle color (COMPLETED)
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: '#1c1d3e' // circle color (ACTIVE)
    },
    '.MuiStepLabel-labelContainer span': {
        'fontFamily': 'Barlow'
    }
}

export default function ConfirmacionMP() {
    const dispatch = useDispatch();
    const token = useSelector(state => {return state.token});

    const isMobile = useMediaQuery('(max-width: 550px)');

    const Navigate = useNavigate();

    const [envio, setEnvio] = useState({});
    const [sucursalDetails, setSucursalDetails] = useState(null);

    let productsStorage = localStorage.getItem('products');
    let envioStorage = localStorage.getItem('envio');

    const handleCheckoutEnd = function(){  // Termina el proceso de checkout

        localStorage.setItem('products', JSON.stringify([]));
        localStorage.setItem('envio', JSON.stringify([]));
        document.documentElement.style.overflowY = 'unset';
        document.documentElement.style.overflowX = 'hidden';
        dispatch(updateCart());
        Navigate("/");
    }

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(() => {

        setEnvio(JSON.parse(envioStorage));

        const cleanCart = () => {
            localStorage.setItem('order', JSON.stringify([]));
        }
        cleanCart();

        if(!token){  // Si no hay token, lo pedimos
            dispatch(getToken());
        } else {
            const getSucursal = async () => {
                if (envio?.sucursal) {
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getsucursales.php?token=${token}&id_sucursal=${envio.sucursal}`);
                        setSucursalDetails(response.data);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }

            getSucursal();
        }

        if(!JSON.parse(productsStorage)?.length){ //Si no hay productos redirigimos al home
            Navigate('/');
        }

        dispatch(updateCart());

        return () => {
            localStorage.setItem('products', JSON.stringify([]));
        }

    },[token, dispatch]);

    return (
        <div className={s.container}>

            <div className={s.stepperContainer}>
                <Stepper activeStep={2}>
                    <Step sx={stepperStyles}>
                        <StepLabel>
                            {
                                !isMobile ?
                                "Facturación y Envío"
                                :
                                ""
                            }
                        </StepLabel>
                    </Step>
                    <Step sx={stepperStyles}>
                        <StepLabel>
                            {
                                !isMobile ?
                                "Medio de Pago"
                                :
                                ""
                            }
                        </StepLabel>
                    </Step>
                    <Step sx={stepperStyles}>
                        <StepLabel>Confirmación</StepLabel>
                    </Step>
                </Stepper>
            </div>

            <div className={s.formContainer}>
                <div className={s.btnsContainer}>
                    <div className={s.textFormContainer}>
                        <h2>¡Gracias por su compra!</h2>
                        {
                            sucursalDetails ?
                            <>
                                <p>
                                    La operación se ha realizado con éxito.<br/>
                                    Estos son los datos de la sucursal:
                                </p>
                                <>
                                    {
                                        sucursalDetails.map(elem => {
                                            return (
                                                <p>
                                                    <b>Dirección:</b> {elem.direccion}<br/>
                                                    <b>Sucursal:</b> {elem.sucursal}<br/> 
                                                    <b>Teléfono:</b> {elem.telefono}
                                                </p>
                                            )
                                        })
                                    }
                                </>
                            </>

                            :

                            <p>
                                La operación se ha realizado con éxito.<br/>
                                En breve recibirá un correo con la factura y nos comunicaremos con usted 
                                para coordinar el envío.
                            </p>
                        }
                    </div>
                    <div className={s.btnBackContainer}>
                        <CallToAction content="Home" onClick={handleCheckoutEnd} />
                    </div>
                </div>
                <div className={s.resumenContainer}>
                    <Cart type="checkout" totalEnvio={envio?.totalEnvio} cp={envio?.cp}/>
                </div>
            </div>
        </div>
    )
}