import React, { useState, useEffect } from 'react';
import s from "./Sucursales.module.css";
import { CallToActionOutline, CallToActionOutlineBooks } from '../../components/CallToAction/CallToAction';
import { InfoCompras } from "../../components/InfoCompras/InfoCompras";
import logo from "../../assets/agape logo rotado.png";
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import SucuResp from '../../components/SucuResp/SucuResp';
import { trackPage } from '../../helpers';

Modal.setAppElement(document.getElementById('root'));

export default function Sucursales() {

    const [locales, setlocales] = useState([]);
    const [sucuResp, setsucuResp] = useState(false);
    const [selectedLocales, setSelectedLocales] = useState(null);
    const [overlay, setOverlay] = useState(false);

    const token = useSelector(state => state.token);

    const topOfPageElement = document.getElementById("top-of-page-mobile");

    const modalStyles = {
        overlay: {
            zIndex: 100,
            background: "#00000060",
            height: "100vh",
            width: "120vw",
            marginLeft: "23vw",
            left: "0",
            transform: !overlay ? "translateX(-360px)" : "translateX(0)",
            transition: "all .3s ease-in-out"
        }
    };
    const handleOpenOverlay = () => { //Manejamos el overlay del modal de filtros en mobile para darle estilos
        setOverlay(true);
    }
    const handleCloseOverlay = () => {
        setOverlay(false);
    }

    const modalSucuControl = function (bool) {
        setsucuResp(bool);
    }

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if (sucuResp) {
            document.documentElement.style.overflowY = 'hidden';
            document.documentElement.style.overflowX = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'unset';
            document.documentElement.style.overflowX = 'hidden';
        }

    }, [sucuResp, topOfPageElement]);

    useEffect(() => {
        const fetchLocales = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API}servicios/getsucursales.php?token=${token}`,
                    {
                        method: 'GET'
                    }
                );
                const data = await response.json();
                if(Array.isArray(data)) {
                    setlocales(data);
                }

            } catch (error) {
                console.error('Error al obtener los locales:', error);
            }
        };

        fetchLocales();
    }, [token]);


    const cambiarlocal = function (local) {
        setSelectedLocales(local)
    }

    const mostrarMapa = () => {
        const localSeleccionado = locales?.find((local) => local.id_sucursal === selectedLocales);

        if(localSeleccionado) {
            return <iframe title='Maps' src={`${localSeleccionado.google_maps}`} className={s.iframe} allowFullScreen></iframe>
        } else {
            return <iframe title='Maps' src='https://www.google.com/maps/d/embed?mid=12yI04TtcQZa2iMo0x7XlZiSvgTs' className={s.iframe} allowFullScreen></iframe>
        }
    }

    const mostrarLocal = () => {
        const localSeleccionado = locales?.find((local) => local.id_sucursal === selectedLocales);
        return(
            <>
                <h3 style={{marginTop: "2rem"}}>{localSeleccionado.sucursal}</h3>
                <ul>
                    <li>Dirección: {localSeleccionado?.direccion}</li>
                    {
                        localSeleccionado?.telefono !== "" &&
                        <li>Telefóno: {localSeleccionado?.telefono}</li>
                    }
                    {
                        localSeleccionado?.whatsapp !== "" &&
                        <li>Whatsapp: {localSeleccionado?.whatsapp}</li>
                    }
                    <li>Correo electrónico: {localSeleccionado?.email}</li>
                </ul>
            </>
        )
    }

    const handleWasap = function (e) {
        e.preventDefault();
        const localSeleccionado = locales?.find((local) => local.id_sucursal === selectedLocales);
        if (localSeleccionado?.whatsapp && localSeleccionado?.whatsapp !== "") {
            let str = localSeleccionado.whatsapp;
            str.replace(/\D/g, '');
            window.open(`https://wa.me/+549${str}`, '_blank');
        } else {
            window.open(`https://wa.me/+5491132640285`, '_blank');
        }
    }

    const capitalizeFirstLetters = function (text) {
        if (!text) return '';

        return text
            .toLowerCase()
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    return (    
        <div className={s.container}>

            <div className={s.column}>
                <div className={s.columnHeader}>
                    <div className={s.breadCrumbs}>
                        <p>Sucursales</p><p>/</p>
                    </div>
                </div>
                <div className={s.columnContent}>
                    <div className={s.category}>
                        <h3>CABA</h3>
                        <ul>
                            {
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia === "Capital Federal") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal); }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className={s.category}>
                        <h3>Provincia Bs. As.</h3>
                        <ul>
                            {
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia === "Buenos Aires") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal); }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className={s.category}>
                        <h3>Interior</h3>
                        <ul>
                            {
                                locales.length !== 0 &&
                                locales.map(local => {
                                    if(local.provincia !== "Buenos Aires" && local.provincia !== "Capital Federal") {
                                        return (
                                            <li onClick={() => { cambiarlocal(local.id_sucursal); }} key={local.id_sucursal}>
                                                {capitalizeFirstLetters(local.sucursal)}
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={s.marcaDeAguaWrapper}>
                    <img alt='Marca de agua' src={logo} />
                </div>
            </div>

            <div className={s.content}>
                <section className={s.sucursalContainer}>
                    <div className={s.sucuresp}>
                        <CallToActionOutlineBooks content="Más Sucursales" onClick={() => { modalSucuControl(true); }} />
                    </div>
                    {
                        mostrarMapa()
                    }
                    <div className={s.data}>
                        {
                            selectedLocales !== null &&
                            mostrarLocal()
                        }
                        <div className={s.btnsContainer}>
                            <CallToActionOutline content="WhatsApp" onClick={handleWasap} detailStyle={true} />
                        </div>
                    </div>

                </section>
                <InfoCompras />
            </div>
            <Modal
                isOpen={sucuResp}
                onRequestClose={() => { modalSucuControl(false) }}
                style={modalStyles}
                ariaHideApp={false}
                className="modalFilterContainer"
                onAfterOpen={handleOpenOverlay}
                onAfterClose={handleCloseOverlay}
            >
                <SucuResp modalSucuControl={modalSucuControl} 
                    cambiarlocal={cambiarlocal} 
                    locales={locales} 
                    capitalizeFirstLetters={capitalizeFirstLetters} 
                />
            </Modal>

        </div>
    )
}

