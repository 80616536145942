import React, { useState, useEffect } from 'react';
import s from "./Libros.module.css";
import { useSelector, useDispatch, connect } from 'react-redux';
import { getLibros, getCategorias, getToken } from '../../redux/actions';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Card from '../../components/Card/Card';
import { CallToAction, CallToActionOutlineBooks, SelectOutlineOrder } from '../../components/CallToAction/CallToAction';
import { InfoCompras } from "../../components/InfoCompras/InfoCompras";
import { ArrowForwardIos } from '@mui/icons-material';
import { ArrowUpward } from '@mui/icons-material';
import logo from "../../assets/agape logo rotado.png";
import axios from 'axios';
import { Search, Close } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { capitalizeWords } from '../../helpers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useMediaQuery from "../../hooks/useMediaQuery";
import Modal from 'react-modal';
import FiltrosModalResp from "../../components/FiltrosModalResp/FiltrosModalResp";
import "../../components/Navbar/NavbarModalStyles.css";
import { trackPage } from '../../helpers';

function Libros() {

    const [libros, setLibros] = useState([]); // Libros a renderizar
    const [autorTextInput, setAutorTextInput] = useState("");  // Input de busqueda por autor
    const [editorialTextInput, setEditorialTextInput] = useState("");  // Input de busqueda por editorial
    const [priceTextInput, setPriceTextInput] = useState({desde: null, hasta: null});  // Input de busqeuda por precio
    const [editoriales, seteditoriales] = useState([]);  // Editoriales a listar en la columna
    const [autores, setautores] = useState([]);  // Autores a listar en la columna
    const [verMas, setVerMas] = useState({tema: false, editorial: false, coleccion: false});  // Que botón de "Ver mas" de la columna fue clickeado.
    const [loading, setLoading] = useState(true);  // Renderiza "Cargando..." cuando es true.
    const [ordenarHabilitado, setOrdenarHabilitado] = useState(false);
    const [paginaActual, setPaginaActual] = useState(1);
    const [paginaMayor, setPaginaMayor] = useState([]); //Guarda las páginas visitadas para no traer libros de más desde back.
    const [menuFiltros, setMenuFiltros] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [isChecked, setIsChecked] = useState(false); //Maneja el valor del checkbox de filtro oferta en mobile

    const isMobile = useMediaQuery('(max-width: 768px)');
    const topOfPageElement = document.getElementById("top-of-page-mobile");
    
    const modalStyles = {
        overlay: {
            zIndex: 100,
            background: "#00000060",
            height: "100vh",
            width: "120vw",
            marginLeft: "23vw",
            left: "0",
            transform: !overlay ? "translateX(-360px)" : "translateX(0)",
            transition: "all .3s ease-in-out"
        }
    };
    const handleOpenOverlay = () => { //Manejamos el overlay del modal de filtros en mobile para darle estilos
        setOverlay(true);
    }
    const handleCloseOverlay = () => {
        setOverlay(false);
    }

    // Filtros activos en este momento. Cada propiedad es un parametro que podria recibir la query.
    // Cuando esta seteada alguna de las propiedades, van a ser listadas en el string de la query junto con su valor
    // limite_inferior y limite_superior son obligatorias para el backend.
    // Por un error de convencion del nombre del backend, limite_inferior es a partir de que libro vamos a traer. Mientras que limite_superior es la cantidad de libros que vamos a traer.
    const [filtros, setFiltros] = useState({ 
        limite_inferior: 0,
        limite_superior: 20,
        id_tema: [],
        precio_desde: null,
        precio_hasta: null,
        filtro_autor: [],
        filtro_coleccion: [],
        filtro_editoriales: [],
        filtro_titulo: null,
        orden: null,
        campo: null,
        oferta: null
    });

    const [searchParams, setSearchParams] = useSearchParams()  // Nos busca parametros de busqueda del url.

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.categorias);
    const token = useSelector(state => state.token);

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{
        setLoading(true);

        if(!token){
            dispatch(getToken());
        }

        dispatch(getCategorias());

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if(searchParams.get('search')){ // Si hay un parametro search, buscamos ese libro por titulo.

            handleCategorias("search", searchParams.get('search'));

        } else if (searchParams.get('editorial')) {  // Si hay un parametro editorial, buscamos libros por editorial.

            handleCategorias("editoriales", searchParams.get('editorial'));
            
        } else if(searchParams.get('tema')){

            handleCategorias("temas", searchParams.get('tema')); // Si hay un parametro search, buscamos ese id_tema

        } else if(searchParams.get('autor')){

            handleAutor(null, searchParams.get('autor'));

        } else {  // Si no, traemos todos los libros de editorial agape.

            let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;
            fetchData(query);

        }

    },[dispatch, token, topOfPageElement])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url

        if(searchParams.get('search')){

            handleCategorias("search", searchParams.get('search'))

        } else if (searchParams.get('editorial')) {  // Si hay un parametro editorial, buscamos libros por editorial.

            handleCategorias("editoriales", searchParams.get('editorial'));
            
        } else if(searchParams.get('tema')){

            handleCategorias("temas", searchParams.get('tema'));

        } else if(searchParams.get('autor')){

            handleAutor(null, searchParams.get('autor'));

        } else {
            let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;
            fetchData(query);
        }

    },[window.location.href])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url
        if (editorialTextInput && editorialTextInput.length > 2) {
            handleEditorialAutocomplete();
        } else{
            seteditoriales([]);
        }
    },[editorialTextInput])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url
        if (autorTextInput && autorTextInput.length > 2) {
            handleAutorAutocomplete();
        } else {
            setautores([]);
        }
    },[autorTextInput])

    async function fetchData(query){

        // fetchData hace la query que recibe por parametro como string. Cada modificaion a los filtros concatena nuevos parametros en el string de la query.
        // Por ejemplo: Si filtros tiene seteado id_tema: 22 y filtro_autor: papa, el string que reciba query sera:
        // https://agape-libros.com.ar/2023/servicios/getlibros.php?token=${token}&limite_inferior=0&limite_superior=30&id_tema=22&filtro_autor=papa

        setLibros([]);

        let promise;
        try{
            promise = await axios.get(query);
        } catch(e){
            console.log(e);
        }

        if(!promise.data || !Array.isArray(promise.data)){
            setLibros([]);
        } else {
            setLibros(promise.data);
        }

        setLoading(false);
        setPaginaActual(1);
        setPaginaMayor([]);
    }

    const handleCategorias = function(type, data){  // Llamada al seleccionar un tema, editorial, coleccion u orden. 

        // Setea una variable query con el url base e itera sobre el objeto estado filtros, concatenando todos los parametros que estan seteados en el estado.
        // Y finalmente actualizando el estado con el nuevo valor que se quiere agregar.
        
        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        if(type === "temas"){  // Si el tipo de variable a setear es temas...
            for (const [key, value] of Object.entries(filtros)) {   // Iteramos sobre el objeto filtros
                if(key === "limite_inferior"){                      // Asiganmos 0 a query en limite inferior para que siempre
                    query = query + `&${key}=0`;                    // veamos la primer página al cambiar de temas
                } else if(key === "id_tema"){                        // Si estamos en la iteracion id_tema
                    if(data && filtros.id_tema.length > 0){
                        query = query + `&${key}=${filtros.id_tema.join(',')},${data}`; //Si ya hay temas en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_tema: [...prev.id_tema, data]})); // Guardamos el nuevo estado con el nuevo valor id_tema seteado.
                    } else if(data && filtros.id_tema.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_tema: [data]}));
                    }
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){  // Si no, la iteracion actual tiene algo seteado
                        query = query + `&${key}=${value}`;                                                // Lo agregamos tambien
                    }
                }
            }
        }

        if(type === "editoriales"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtro_editoriales"){
                    if(data && filtros.filtro_editoriales.length > 0){
                        query = query + `&${key}=${filtros.filtro_editoriales.join(',')},${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_editoriales: [...prev.filtro_editoriales, data]}));
                    } else if(data && filtros.filtro_editoriales.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_editoriales: [data]}));
                    } 
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }

            setEditorialTextInput('');
        }

        if(type === "colecciones"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtro_coleccion"){
                    if(data && filtros.filtro_coleccion.length > 0){
                        query = query + `&${key}=${filtros.filtro_coleccion.join(',')},${data}`; 
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_coleccion: [...prev.filtro_coleccion, data]})); 
                    } else if(data && filtros.filtro_coleccion.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_coleccion: [data]}));
                    }
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "search"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtro_titulo"){
                    query = query + `&${key}=${data}`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_titulo: data}));
        }

        if(type === "oferta"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "oferta"){
                    query = query + `&${key}=1`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, oferta: "1"}));
        }

        // Los filtros campo y orden representan el select de "Ordenar por". Campo puede valer "precio", "titulo" y "antiguedad". Orden puede valer "ASC" o "DESC".
        // Para este caso, el parametro "data" llega como, por ejemplo: precio_ASC.

        if(type === "orden"){

            if(data === "default"){
                setFiltros((prev)=>({...prev, campo: null, orden: null}));
                return;
            }

            let [campo, orden] = data.split("_");

            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "campo"){
                    query = query + `&campo=${campo}`;

                } else if(key === "orden"){
                    query = query + `&orden=${orden}`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, campo: campo, orden: orden}));
        }

        fetchData(query);
    }

    const handleAutor = function(e, autor){

    // Misma iteracion que venimso haciendo pero para el autor. Esta separada de la funcion handleCategorias porque esta funcion se ejectura cuando se hace submit al input de autor.
        if(e) {
            e.preventDefault();
        }
        
        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        for (const [key, value] of Object.entries(filtros)) {
            if(key === "limite_inferior"){
                query = query + `&${key}=0`;
            } else if(key === "filtro_autor"){
                if(autor && filtros.filtro_autor.length > 0){
                    query = query + `&${key}=${filtros.filtro_autor.join(',')},${autor}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_autor: [...prev.filtro_autor, autor]}));
                } else if(autor && filtros.filtro_autor.length === 0){
                    query = query + `&${key}=${autor}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_autor: [autor]}));
                } else {
                    query = query + `&filtro_titulo=${autorTextInput}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_titulo: autorTextInput}));
                }
            } else {
                if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                    query = query + `&${key}=${value}`;
                }
            }
        }

        setAutorTextInput("");
        fetchData(query);
    }

    const handleAutorAutocomplete = async function(){

        // Al buscar un autor, se hace la query y se listan para luego ser clickeadas y filtrar por ellas.

        if(autorTextInput === "") return;
 
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getautores.php?token=${token}&cadena=${autorTextInput}`);

        let response = promise.data;

        if(response){
            setautores(response);
        } else {
            setautores([]);
        }
    }

    const handleEditorial = function(e){
            e.preventDefault();
            
            setLoading(true);
    
            let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;
    
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtro_editoriales"){
                    query = query + `&filtro_titulo=${editorialTextInput}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_titulo: editorialTextInput}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
    
            setEditorialTextInput("");
            fetchData(query);
        }

    const handleEditorialAutocomplete = async function(){

        // Al buscar una editorial, se hace la query y se listan para luego ser clickeadas y filtrar por ellas.

        if(editorialTextInput === "") return;
 
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/geteditoriales.php?token=${token}&cadena=${editorialTextInput}`);

        let response = promise.data;

        if(response){
            seteditoriales(response);
        } else {
            seteditoriales([]);
        }

    }

    const handlePrice = function(e){

        // Mismo que veniamos haciendo pero con precios. Hay que hacer 4 casos porque puede haber
        // Precio hasta, pero no desde
        // Precio desde, pero no hasta
        // Precio desde y hasta
        // Ninguno
        // Puede ser que se pueda optimizar pero yo ya me fui jaja

        e.preventDefault();

        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        if(priceTextInput.desde && !priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "precio_desde"){
                    query = query + `&${key}=${priceTextInput.desde}`;
                } else {
                    if(((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) && key !== "precio_hasta"){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: null}));
        }

        else if(!priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "precio_hasta"){
                    query = query + `&${key}=${priceTextInput.hasta}`;
                } else {
                    if(((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) && key !== "precio_desde"){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: priceTextInput.hasta}));
        }

        else if(priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                }

                if(key === "precio_desde"){
                    query = query + `&precio_desde=${priceTextInput.desde}`;
                }
                
                if(key === "precio_hasta"){
                    query = query + `&precio_hasta=${priceTextInput.hasta}`;
                } 

                if(key !== "precio_desde" && key !== "precio_hasta"){
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: priceTextInput.hasta}));
        }

        else if(!priceTextInput.desde && !priceTextInput.hasta){
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: null}));
            return;
        }

        fetchData(query);
    }

    const fetchNewPage = async function(){
        
        // Es llamada cuando se clickea el boton "Ver mas" de las tarjetas.
        // Usa la misma query anterior, pero cambia la variable "limite_inferior" (que como vimos, en realidad rpresenta a partir de que libro vamos a traer nuevos libros)
        // Y asi busca los proximos 20 libros. Despues junta este nuevo array con el array del estado libros.
        
        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if (key === "limite_inferior") {
                    query = query + `&${key}=${value + 20}`;
                    setFiltros((prev)=>({...prev, limite_inferior: value + 20}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        let promise;

        try{
            promise = await axios.get(query);
        } catch(e){
            console.log(e);
        }

        if (!promise.data){
            return;
        } else {
            setLibros([...libros, ...promise.data]);
        }
        
        setLoading(false);
    }

    const handlePagination = async function(page) {
        setPaginaMayor([...paginaMayor, page]);
        let numeroMayor = Math.max(...paginaMayor);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if(key === "limite_inferior" && page > numeroMayor){
                    query = query + `&${key}=${value + 20}`;
                    setFiltros((prev)=>({...prev, limite_inferior: value + 20}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        let promise;
        try{
            if (page > numeroMayor) {
                promise = await axios.get(query);
            } else {
                setPaginaActual(page);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } catch(e){
            console.log(e);
        }

        if (promise?.data){
            setLibros([...libros, ...promise.data]);
            setPaginaActual(page);
        } else {
            return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
    }

    const formatActiveFilters = function(search){

        // Maneja las burbujitas que aparecen cuando hay un filtro seleccionado.

        let bubbles = []; // Bubbles es un array de objetos con info del filtro, que tiene las burbujas a renderizar.

        // El termino burbujas lo invente yo, no se como se llaman.

        if(filtros.id_tema.length > 0){
            filtros.id_tema.forEach(elem => {

                const themeInCategory = categorias?.temas?.find(tema => tema.id_tema === elem)?.tema;
                const themeName = `Tema: ${themeInCategory}`;
                
                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === themeName)) {
                    bubbles.push({ value: themeName, key: "id_tema" });
                }
            });
        }
        if(filtros.precio_desde){
            bubbles.push({value: `Precio Min: $${filtros.precio_desde}`, key: "precio_desde"})
        }
        if(filtros.precio_hasta){
            bubbles.push({value: `Precio Max: $${filtros.precio_hasta}`, key: "precio_hasta"})
        }
        if(filtros.filtro_autor.length > 0){
            filtros.filtro_autor.forEach(elem => {
                const authorName = `Autor: ${elem}`;
                
                if (!bubbles.some(item => item.value === authorName)) {
                    bubbles.push({ value: authorName, key: "filtro_autor" });
                }
            });
        }
        if(filtros.filtro_coleccion){
            filtros.filtro_coleccion.forEach(elem => {

                const collectionInCategory = categorias?.colecciones?.find(coleccion => coleccion.coleccion === elem)?.coleccion;
                const collectionName = `Colección: ${collectionInCategory}`;

                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === collectionName)) {
                    bubbles.push({ value: collectionName, key: "filtro_coleccion" });
                }
            });
        }
        if(filtros.filtro_editoriales.length > 0){
            filtros.filtro_editoriales.forEach(elem => {
                const editorialName = `Editorial: ${elem}`;
                
                if (!bubbles.some(item => item.value === editorialName)) {
                    bubbles.push({ value: editorialName, key: "filtro_editoriales"});
                }
            });
        }
        if(filtros.filtro_titulo) {
            if(search) {
                bubbles.push({value: `Búsqueda: ${search}`, key: "filtro_titulo"});
            } else {
                bubbles.push({value: `Búsqueda: ${filtros.filtro_titulo}`, key: "filtro_titulo"});
            }
        }
        if(filtros.oferta){
            bubbles.push({value: `Oferta: Si`, key: "oferta"})
        }

        if(bubbles.length && !ordenarHabilitado){
            setOrdenarHabilitado(true);
        }

        return <>
        {
            bubbles.length > 1 && !isMobile &&
            <span className={s.bubblesCleanButton} onClick={() => cleanActiveFilters()}>
                Limpiar filtros
            </span>
        }
        {
            bubbles.map((bubble, i)=>{
                return (
                        <div>
                            {
                                !isMobile ? 
                                    <div key={i} className={s.bubble}>
                                        <p>{capitalizeWords(bubble.value)}</p>
                                        <Close sx={{ fontSize: 25, color: "#1c1d3e", cursor: "pointer"}} 
                                            onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}/>
                                    </div>
                                    :
                                    <div key={i} className={s.bubble} onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}>
                                        <p>
                                            {
                                                bubble.key === "oferta" ?
                                                "Oferta"
                                                :
                                                capitalizeWords(bubble.value.substring(bubble.value.indexOf(":") + 2))
                                            }
                                        </p>
                                    </div>
                            }
                        </div>
                )
            })
        }
        </>

    }

    const handleBubbleClose = function(targetKey, bubblesLength, targetValue) {

        // Al cerrar una burbuja, generamos el nuevo string query sin ese parametro y seteamos el estado de filtros sacando ese parametro.
        let cleanValue;
        setLoading(true);

        //Modificamos el string para quitarle el "Autor:". En el caso de tema tambien obtenemos el id y en coleccion el nombre para poder eliminarlos.
        if (targetKey === "id_tema") {
            cleanValue = categorias?.temas?.find(tema => tema.tema === targetValue.substring(targetValue.indexOf(":") + 2))?.id_tema;
        } else if (targetKey === "filtro_coleccion") {
            cleanValue = categorias?.colecciones?.find(elem => elem.coleccion === targetValue.substring(targetValue.indexOf(":") + 2))?.coleccion;
        } else {
            cleanValue = targetValue.substring(targetValue.indexOf(":") + 2);
        }

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if(key === targetKey && (Array.isArray(value) && value.length > 0)){  //Si el valor es un array ingresamos para eliminar el elemento que se quiere eliminar
                    let deleteValue = filtros[targetKey].filter(elem => elem !== cleanValue);
                    query = query + `&${key}=${deleteValue.join(',')}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, [targetKey]: deleteValue}));
                } else if(key === targetKey && !Array.isArray(value)){
                    setFiltros((prev)=>({...prev, [targetKey]: null}));
                } else if (key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        // Si se cerraron todas las burbujas, no hay nada que filtrar y nos traemos todos los libros de editorial agape.

        if(bubblesLength === 1){
            query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;

            // Si no hay filtros, deshabilitamos el select de orden, porque el servicio de recomendados no tiene filtro por orden. (Ahora traemos todos los libros, no los recomendados).
            setOrdenarHabilitado(false)
        }

        if (targetKey === "oferta") { //Cambiamos el estado del checkbox de oferta en mobile para desmarcarlo
            setIsChecked(!isChecked);
        }
        
        fetchData(query);
    }

    const cleanActiveFilters = function() {
        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;

        for (const [key, value] of Object.entries(filtros)) {
            if (typeof value === "string") {
                setFiltros((prev)=>({...prev, [key]: null}));
            }
            if ((Array.isArray(value) && value.length > 0)) {
                setFiltros((prev)=>({...prev, [key]: []}));
            }
        }

        setOrdenarHabilitado(false);
        fetchData(query);
    }

    const handleInputFormat = function(search, fn) {
        // Elimina caracteres especiales y números del valor del input
        const cleanedValue = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z\s]/g, '');
        fn(cleanedValue);
    }

    const modalFiltrosControl = function (bool) {
        setMenuFiltros(bool);
    }

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            handleCategorias("oferta");
        } else {
            handleBubbleClose("oferta", "", "Oferta: Si");
        }
    }

    return (
        <>
            {/*  Modal filtros en mobile */}
            {
                isMobile &&
                <>
                    <div className={s.mobileFilterButtons}>
                        <CallToActionOutlineBooks content="Filtros" onClick={() => modalFiltrosControl(true)} />
                        <SelectOutlineOrder content="Ordenar por" onChange={(e)=>{ handleCategorias("orden", e.target.value) }} />
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <Modal
                        isOpen={menuFiltros}
                        onRequestClose={() => { modalFiltrosControl(false) }}
                        style={modalStyles}
                        ariaHideApp={false}
                        className="modalFilterContainer"
                        onAfterOpen={handleOpenOverlay}
                        onAfterClose={handleCloseOverlay}
                    >
                        <FiltrosModalResp modalMenuRespControl={modalFiltrosControl} categorias={categorias} handleCategorias={handleCategorias} 
                            handleAutor={handleAutor} handleInputFormat={handleInputFormat} setAutorTextInput={setAutorTextInput} autores={autores} 
                            handleEditorial={handleEditorial} setEditorialTextInput={setEditorialTextInput} editoriales={editoriales} 
                            handlePrice={handlePrice} setPriceTextInput={setPriceTextInput} handleBubbleClose={handleBubbleClose} 
                            isChecked={isChecked} handleCheckbox={handleCheckbox} ofertas={categorias?.ofertas} />
                    </Modal>
                </>
            }
        
        <div className={s.container}>

            {
                !isMobile &&
                <div className={s.column}>
                    <div className={s.columnHeader}>
                        <p>Libros</p><p>/</p>
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <div className={s.columnContent}>

                        {/* Select de orden */}
                        { 
                            ordenarHabilitado == true && 
                            <div className={s.selectContainer}>
                                <p>Ordenar por</p>
                                <select onChange={(e)=>{ handleCategorias("orden", e.target.value) }} id="select">
                                    <option value="default">Seleccione</option>
                                    <option value="precio_DESC">Mayor precio</option>
                                    <option value="precio_ASC">Menor precio</option>
                                    <option value="titulo_ASC">A - Z</option>
                                    <option value="titulo_DESC">Z - A</option>
                                    <option value="antiguedad_DESC">Mas reciente</option>
                                    <option value="antiguedad_ASC">Mas antiguo</option>
                                </select>
                            </div>
                        }

                        {/* Filtro de ofertas si existen */}
                        {
                            categorias?.ofertas?.length > 0 &&
                            <div className={s.ofertas} onClick={() => handleCategorias("oferta")}>
                                <h3>Ofertas</h3>
                            </div>
                        }

                        {/* Lista de temas */}
                        <div className={s.category}>
                            <h3>Tema</h3>
                            <ul>
                                {
                                    categorias?.temas?.length === 0 &&
                                    <LoadingSpinner/>
                                }
                                {
                                    categorias?.temas?.length > 5 ?
                                    categorias?.temas?.map((tema, index)=>{
                                        if(index < 5 || verMas.tema){
                                            return <li key={index} onClick={(e)=>{handleCategorias("temas", tema.id_tema)}}>{tema.tema}</li>
                                        }
                                    }) :
                                    categorias?.temas?.map((tema, index)=>{
                                        return <li key={index} onClick={(e)=>{handleCategorias("temas", tema.id_tema)}}>{tema.tema}</li>
                                    })
                                }
                                {
                                    (categorias?.temas?.length > 5 && !verMas.tema)&&
                                    <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, tema: true})) }}>Mostrar más</li>
                                }
                            </ul>
                        </div>

                        {/* Input de busqueda de autor */}
                        <div className={s.category}>
                            <h3>Autor</h3>
                            <form onSubmit={handleAutor}>
                                <div className={s.inputWrapper}>
                                    <Search sx={{ fontSize: 25, color: "rgba(60, 60, 67, 0.6)"}}/>
                                    <input type='text' placeholder='BUSCAR' onChange={(e)=>{handleInputFormat(e.target.value, setAutorTextInput)}} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                    <ul>
                                        {
                                            autores?.length > 5 ?
                                            autores?.map((autor, index)=>{
                                                if(index < 5){
                                                    return <li key={index} onClick={(e)=>{handleAutor(e, autor.autor)}}>{capitalizeWords(autor.autor)}</li>
                                                }
                                            }) :
                                            autores?.map((autor, index)=>{
                                                return <li key={index} onClick={(e)=>{handleAutor(e, autor.autor)}}>{capitalizeWords(autor.autor)}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </form>
                        </div>

                        {/* Input de busqeuda de editoriales y lista de editoriales */}
                        <div className={s.category}>
                            <h3>Editorial</h3>
                            <form onSubmit={handleEditorial}>
                                <div className={s.inputWrapper}>
                                    <Search sx={{ fontSize: 25, color: "rgba(60, 60, 67, 0.6)"}}/>
                                    <input type='text' placeholder='BUSCAR' onChange={(e)=>{handleInputFormat(e.target.value, setEditorialTextInput)}} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                    <ul>
                                        {
                                            editoriales?.length > 5 ?
                                            editoriales?.map((editorial, index)=>{
                                                if(index < 5 || verMas.editorial){
                                                    return <li key={index} onClick={(e)=>{handleCategorias("editoriales", editorial.editorial)}}>{editorial.editorial}</li>
                                                }
                                            }) :
                                            editoriales?.map((editorial, index)=>{
                                                return <li key={index} onClick={(e)=>{handleCategorias("editoriales", editorial.editorial)}}>{editorial.editorial}</li>
                                            })
                                        }
                                        {
                                            (editoriales.length > 5 && !verMas.editorial)&&
                                            <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, editorial: true})) }}>Mostrar más</li>
                                        }
                                    </ul>
                                </div>
                            </form>
                        </div>

                        {/* Lista de colecciones */}
                        <div className={s.category}>
                            <h3>Colección</h3>
                            <ul>
                                {
                                    categorias?.colecciones?.length === 0 &&
                                    <LoadingSpinner/>
                                }
                                {
                                    categorias?.colecciones?.length > 5 ?
                                    categorias?.colecciones?.map((coleccion, index)=>{
                                        if(index < 5 || verMas.coleccion){
                                            return <li key={index} onClick={(e)=>{handleCategorias("colecciones", coleccion.coleccion)}}>{coleccion.coleccion}</li>
                                        }
                                    }) :
                                    categorias?.colecciones?.map((coleccion, index)=>{
                                        return <li key={index} onClick={(e)=>{handleCategorias("colecciones", coleccion.coleccion)}}>{coleccion.coleccion}</li>
                                    })
                                }
                                {
                                    (categorias?.colecciones?.length > 5 && !verMas.coleccion)&&
                                    <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, coleccion: true})) }}>Mostrar más</li>
                                }
                                {/* <li className={s.mostrarMas}>Mostrar más</li> */}
                            </ul>
                        </div>

                        {/* Inputs de busqueda de precios */}
                        <div className={s.category}>
                            <h3>Precio</h3>
                            <form onSubmit={handlePrice}>
                                <div className={s.precioContainer}>
                                    <input type='number' placeholder='Mínimo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, desde: e.target.value}))}}/>
                                    <input type='number' placeholder='Máximo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, hasta: e.target.value}))}}/>
                                    <button type='submit'>
                                        <ArrowForwardIos sx={{ fontSize: 20, color: "#1C1B1F" }}/>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div onClick={()=>window.scrollTo({top: 0, left: 0, behavior: "smooth"})} className={s.VolArrib}>
                        <ul>
                            <li>Volver Arriba <ArrowUpward/> </li>
                        </ul>
                    </div>

                    {/* Marca de agua del fondo de la columna */}
                    <div className={s.marcaDeAguaWrapper}>
                        <img alt='Marca de agua' src={logo}/>
                    </div>
                </div>
            }

            {/* Renderizamos tarjetas para los libros */}
            <div className={s.content}>
                <SectionTitle content="Libros" styles={true} />
                <div className={s.cards}>
                    {
                        isMobile ? 
                        libros?.map((libro,index)=>{
                            return <Card product={libro} key={index}/>
                        })
                        :
                        libros?.slice((paginaActual - 1) * 20, paginaActual * 20).map((libro,index)=>{
                            return <Card product={libro} key={index}/>
                        })
                    }
                    {
                        loading ?
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>Buscando...</p>
                        </div>
                        
                        :
                        !libros?.length &&
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>No hay resultados</p>
                        </div>
                    }
                </div>

                {/* Boton de ver mas si es que hay mas para ver... (mobile)*/}
                {
                    isMobile && libros?.length !== 0 && libros?.length % 20 === 0 &&
                    <CallToAction content="Ver más" onClick={()=>{fetchNewPage();}}/>
                }
                
                {/* Paginador (tablet, desktop)*/}
                {
                    !isMobile && libros?.length !== 0 &&
                    <Stack spacing={2}>
                        <Pagination 
                            count={libros?.length !== 0 && (libros?.length % 20 === 0 || paginaActual < Math.max(...paginaMayor)) ? paginaActual + 1 : paginaActual}
                            shape="rounded" 
                            page={paginaActual}
                            onChange={(e, page) => handlePagination(page)}
                            color="primary" 
                            sx={{
                                ".Mui-selected": {
                                    color: "white!important",
                                    backgroundColor: "#4d92cf!important",
                                },
                                ".Mui-selected:hover": {
                                    backgroundColor: "#4d92cf!important",
                                }
                            }}
                        />
                    </Stack>
                }

                <InfoCompras/>
            </div>
        </div>
    </>
    )
}

export default connect(null, { getLibros })(Libros);