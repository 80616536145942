import { Link } from "react-router-dom";
import { copyToClipboard } from "../../helpers";
import s from "./Footer.module.css";
import logo from "../../assets/logo_footer.png";
import logoMobile from "../../assets/logo_footerMobile.png";
import wp from "../../assets/whatsApp_icon.png";
import wpMobile from "../../assets/whatsapp_iconMobile.png";
import facebook from "../../assets/facebook_icon.png";
import facebookMobile from "../../assets/facebook_iconMobile.png";
import ig from "../../assets/instagram_icon.png";
import igMobile from "../../assets/instagram_iconMobile.png";
import locationIcon from "../../assets/location_icon.png";
import phoneIcon from "../../assets/phone_icon.png";
import mailIcon from "../../assets/mail_icon.png";
import { ArrowUpward } from '@mui/icons-material';
import useMediaQuery from "../../hooks/useMediaQuery";

export default function Footer() {

    const isMobile = useMediaQuery('(max-width: 768px)');

    const handleWasap = function(e){
        e.preventDefault();
        window.open(`https://wa.me/+5491132640285`,'_blank');
    }

    const handleFacebook = function(e){
        e.preventDefault(); 
        window.open(`https://www.facebook.com/agapelibros`,'_blank');
    }

    const handleInstagram = function(e){
        e.preventDefault();
        window.open(`https://www.instagram.com/agapelibros/`,'_blank');
    }

    const handleGoUp = () => {
        let topOfPageElement;
        if(isMobile) {
            topOfPageElement = document.getElementById("top-of-page-mobile");
        } else {
            topOfPageElement = document.getElementById("top-of-page-desktop");
        }
        topOfPageElement.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <footer className={s.container}>
            <div className={s.goUpContainerTablet} 
                onClick={handleGoUp}
            >
                <ArrowUpward sx={{color:"white"}} />
                <p>Volver Arriba</p>
            </div>

            <div className={s.containerSucursal}>
                <h3>Casa Central</h3>

                <div>
                    <a href="https://www.google.com/maps/place/Libros+%C3%81gape/@-34.591749,-58.510085,15z/data=!4m6!3m5!1s0x95bcb7b4e3e15721:0xd1a9dc97baf9b9e0!8m2!3d-34.5917486!4d-58.5100848!16s%2Fg%2F1hc139m9q?hl=en&entry=ttu"
                        target="_blank">
                        <img src={locationIcon} alt="Icono de ubicación" className={s.location} />
                        <p>Av. San Martín 6863 - Buenos Aires</p>
                    </a>
                </div>
                <div onClick={handleWasap}>
                    <img src={phoneIcon} alt="Icono de teléfono" className={s.phone} />
                    <p>+54 9 11 3264-0285</p>
                </div>
                <div onClick={() => copyToClipboard("comercial@agape-libros.com.ar")}>
                    <img src={mailIcon} alt="Icono de sobre" className={s.mail} />
                    <p>comercial@agape-libros.com.ar</p>
                </div>
            </div>

            <div className={s.containerSocial}>
                <Link to="/" onClick={handleGoUp}>
                    <img src={logo} alt="Logo Agape Libros" className={s.logo} />
                </Link>

                <div className={s.socialIcons}>
                    <img src={wp} alt="Logo Whatsapp" onClick={handleWasap}/>
                    <img src={facebook} alt="Logo Facebook" onClick={handleFacebook}/>
                    <img src={ig} alt="Logo Instagram" onClick={handleInstagram}/>
                </div>

                {/* Seccion mobile */}
                <div className={s.mobileFooterContainer}>
                    <div className={s.goUpContainerMobile} 
                        onClick={handleGoUp}
                    >
                        <ArrowUpward sx={{color:"white"}} />
                        <p>Volver Arriba</p>
                    </div>
                    
                    <div className={s.mobileSocialContainer}>
                        <div className={s.socialIconsMobile}>
                            <img src={igMobile} alt="Logo Instagram" onClick={handleInstagram}/>
                            <img src={wpMobile} alt="Logo Whatsapp" onClick={handleWasap}/>
                            <img src={facebookMobile} alt="Logo Facebook" onClick={handleFacebook}/>
                        </div>

                        <Link to="/" onClick={handleGoUp}>
                            <img src={logoMobile} alt="Logo Agape Libros" className={s.logoMobile} />
                        </Link>
                    </div>
                </div>
            </div>

            <div className={s.containerNavigation}>
                <h3>Navegación</h3>

                <div>
                    <div>
                        <Link to="/" onClick={handleGoUp}>
                            <p>Inicio</p>
                        </Link>
                        <Link to="/Contacto" onClick={handleGoUp}>
                            <p>Contacto</p>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Libros" onClick={handleGoUp}>
                            <p>Libros</p>
                        </Link>
                        <Link to="/Contacto?checkbox=true" onClick={handleGoUp}>
                            <button>
                                Trabaja con nosotros
                            </button>
                        </Link>
                    </div>
                    <Link to="/Sucursales" onClick={handleGoUp} style={{width:"fit-content"}}>
                        <p>Sucursales</p>
                    </Link>
                </div>
            </div>
        </footer>
    )
}
