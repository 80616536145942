import './App.css';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getToken } from './redux/actions';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Libros from "./pages/Libros/Libros";
import Layout from './pages/Layout/Layout';
import Detalle from './pages/Detalle/Detalle';
import Sucursales from './pages/Sucursales/Sucursales';
import Checkout from './pages/Checkout/Checkout';
import ConfirmacionMP from './pages/ConfirmacionMP/ConfirmacionMP';
import Contacto from './pages/Contacto/Contacto';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import ReactGA from "react-ga";

const TRACKING_ID = "G-LWSQ4F9NK3"; //Google Analitycs ID
ReactGA.initialize(TRACKING_ID);

function App(){

    const dispatch = useDispatch();
    const token = useSelector((state)=> state.token);
    const testingEnvironment = process.env.REACT_APP_ENVIRONMENT === "test";

    useEffect(()=>{
        dispatch(getToken());
    },[dispatch])

    useEffect(()=>{  // Nos aseguramos de que siempre haya un token. Si no lo hay, seguimos pidiendolo.
        if(!token){
            dispatch(getToken());
        }
    },[token, dispatch])


    // Toaster habilita el uso de toasts, mensajes de alerta que se ejecutan con las funciones de ./helpers/index.js
    // Por debajo esta el router que devuelve un componente de la carpeta ./Pages para cada caso.
    // Los envuelve en en componenten Layout (./Pages) que renderiza el navbar y el footer sobre cada componente de pagina.
    return (
        <>
            <Toaster/>  
            <BrowserRouter basename={testingEnvironment ? "/test" : "/"}>
                <Routes>
                    <Route path="/" element={<Layout><Home /></Layout>}/>
                    <Route path="/Libros/:search?" element={<Layout><Libros /></Layout>}/>
                    <Route path="/Detalle/*" element={<Layout><Detalle /></Layout>}/>
                    <Route path="/Sucursales" element={<Layout><Sucursales /></Layout>}/>
                    <Route path="/Checkout" element={<Layout><Checkout /></Layout>}/>
                    <Route path="/ConfirmacionMP" element={<Layout><ConfirmacionMP /></Layout>}/>
                    <Route path="/Contacto" element={<Layout><Contacto /></Layout>}/>
                    <Route path="/*" element={testingEnvironment ? null : <Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default connect(null, { getToken })(App);