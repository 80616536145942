import React, { useEffect, useState } from 'react';
import s from "./Home.module.css";
import { useSelector, useDispatch, connect } from 'react-redux';
import { getLibros, getToken } from '../../redux/actions';
import Swiper from '../../components/Swiper/Swiper';
import SwiperCards from "../../components/SwiperCards/SwiperCards";
import { CallToAction } from '../../components/CallToAction/CallToAction';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { InfoCompras } from '../../components/InfoCompras/InfoCompras';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import { trackPage } from '../../helpers';

function Home() {

    const dispatch = useDispatch();
    const libros = useSelector((state)=> {return state.libros});  // Subscripciones a propiedades del estado global
    const token = useSelector((state)=> {return state.token});
    const [categorias, setCategorias] = useState([]);  // Cards de categorias para mapear.
    const [showMore, setShowMore] = useState(false);
    const [recomendadosPortada, setRecomendadosPortada] = useState(null);
    const [novedadesPortada, setNovedadesPortada] = useState(null);
    const [ofertas, setOfertas] = useState(null);

    const Navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const topOfPageElement = document.getElementById("top-of-page-mobile");

    useEffect(() => {
        trackPage();
    },[]);
    
    useEffect(()=>{

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if(!token){  // Si no hay token, lo pedimos
            dispatch(getToken());
        } else {
            dispatch(getLibros());
        }

        const fetchData = async function(){
            let promise;

            // Traemos temas y los guardamos en categorias.
            
            if (token) {
                try {
                    promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/gettemas.php?token=${token}&orden=1`);
                } catch (error) {
                    console.log(error);
                }
            }

            if (promise?.data && Array.isArray(promise?.data)){
                setCategorias(promise.data);
            }
        }

        const fetchRecomendadosPortada = async () => {
            let response;

            if(token && !recomendadosPortada) {
                try {
                    response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getcategorias.php?token=${token}&id_categoria=8&limit=1`);
                } catch (error) {
                    console.log(error);
                }
    
                if(token && response.data) {
                    setRecomendadosPortada(response.data);
                }
            }
            return;
        }

        const fetchNovedadesPortada = async () => {
            let response;

            if(token && !novedadesPortada) {
                try {
                    response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getcategorias.php?token=${token}&id_categoria=9&limit=1`);
                } catch (error) {
                    console.log(error);
                }
    
                if(token && response.data) {
                    setNovedadesPortada(response.data);
                }
            }
            return;
        }

        const fetchOfertas = async () => {
            let response;

            if(token && !ofertas) {
                try {
                    response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibrosaccionescom.php?token=${token}`);
                } catch (error) {
                    console.log(error);
                }
    
                if(response.data) {
                    //Solo guardamos las ofertas que tengan el precio de oferta establecido
                    if(Array.isArray(response.data)) {
                        const deleteProductWithNoOffer = response.data.filter(elem => elem.oferta != 0.00)
                        setOfertas(deleteProductWithNoOffer);
                    }
                }
            }
            return;
        }

        const cleanLocalStorage = () => {
            localStorage.setItem('products', JSON.stringify([]));
            localStorage.setItem('envio', JSON.stringify([]));
        }

        fetchRecomendadosPortada();
        fetchNovedadesPortada();
        fetchOfertas();
        fetchData();
        cleanLocalStorage();

    },[token, dispatch]);

    const handleCategorias = function() {
        setShowMore(true);
    }

    const handleCategoriasRedirection = (idCategory) => {
        if(isMobile) {
            const topOfPageElement = document.getElementById("top-of-page-mobile");
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }
        Navigate(`/Libros?tema=${idCategory}`)
    }

    return (
        <div className={s.container}>

            <section name="Hero" className={s.heroContainer}>
                <Swiper/>
                <InfoCompras/>
            </section>

            <section name="Recomendados" className={s.recomendadosContainer}>
                <SectionTitle content="Recomendados del mes" />
                <div className={s.recomendadosContent}>
                    {
                        recomendadosPortada &&
                        <img className={s.recomendadosPortada} alt="Portada novedades" src={`${process.env.REACT_APP_URL_API}admin/${recomendadosPortada[0]?.imagen}`}/>
                    }
                    
                    {
                        !isMobile &&
                        <SwiperCards products={libros.recomendados}/>
                    }
                </div>
                {
                    isMobile &&
                    <SwiperCards products={libros.recomendados}/>
                }
            </section>

            {/* El diseño de santería no esta terminado, por eso no hace nada. */}
            {/* <section name="Santeria" className={s.santeriaContainer}>
                <SectionTitle content="Objetos Religiosos" />
                <div className={s.santeriaContent}>
                    <div className={s.santerias}>
                        <h1 className={s.proximamente}>Próximamente</h1>
                        <div className={s.santeria}>
                            <div>
                                <p>Rosarios</p>
                            </div>
                            <img alt="Santeria Rosarios" src={santeriaRosarios}/>
                        </div>
                        <div className={s.santeria}>
                            <div>
                                <p>Medallas</p>
                            </div>
                            <img alt="Santeria Medallas" src={santeriaMedallas}/>
                        </div>
                        <div className={s.santeria}>
                            <div>
                                <p>Imágenes</p>
                            </div>
                            <img alt="Santeria Imagenes" src={santeriaImagenes}/>
                        </div>
                        <div className={s.santeria}>
                            <div>
                                <p>Cruces</p>
                            </div>
                            <img alt="Santeria Cruces" src={santeriaCruces}/>
                        </div>
                    </div>
                    <CallToAction content="Ver más"/>
                </div>
            </section> */}

            {
                ofertas && ofertas.length > 0 &&
                <section name="Ofertas">
                    <SectionTitle content="Ofertas" />
                    <div className={s.ofertasContent}>
                        <SwiperCards products={ofertas} detailPage={true} />
                    </div>
                </section>
            }

            {/*  El diseño de cuadrenos monasticos no esta terminado, por ende los callToActions no hacen nada todavia  */}
            <section name="Novedades" className={s.novedadesContainer}>
                <SectionTitle content="Novedades" />
                <div className={s.recomendadosContent}>
                    {
                        novedadesPortada &&
                        <img className={s.recomendadosPortada} alt="Portada novedades" src={`${process.env.REACT_APP_URL_API}admin/${novedadesPortada[0]?.imagen}`} />
                    }
                    
                    {
                        !isMobile &&
                        <SwiperCards products={libros.novedades}/>
                    }
                </div>
                {
                    isMobile && 
                    <SwiperCards products={libros.novedades}/>
                }
            </section>

            {/*  Mapeamos las tarjeta de categoria y le asignamos que onClick navegue a /Libros con id como parametro de busqueda  */}

            <section name="Categorias" className={s.categoriasContainer}>
                <SectionTitle content="Temas" />
                <div className={s.categoriasContent}>
                    <div className={s.categorias}>
                    {
                        categorias?.slice(showMore ? undefined : 0, showMore ? undefined : 4).map((categoria, index)=>{    //Agregamos al slice datos si esta activado showMore
                            const imagenUrl = categoria.imagen_tema !== "" ? `${process.env.REACT_APP_URL_API}admin/${categoria.imagen_tema}` : "https://agape-libros.com.ar/imagenes/nolibro.png";

                            return (
                                <div className={s.categoria} onClick={()=>{handleCategoriasRedirection(categoria.id_tema)}} key={index}>
                                    <div>
                                        <p>{categoria.tema}</p>
                                    </div>
                                    <img alt={`Categoría ${categoria.tema}`} 
                                        src={imagenUrl}
                                    />
                                </div>
                            )
                        })
                    }
                    </div>
                </div>

                <div className={s.categoriaShowMore} style={{display: showMore ? "flex" : "none"}}>
                    <CallToAction content="Ver Todo" onClick={() => Navigate("/Libros")} />
                </div>
                
                <div className={s.categoriaShowMore} style={{visibility: showMore ? "hidden" : "visible"}}>
                    <CallToAction content="Ver Más" onClick={handleCategorias} />
                </div>

            </section>

        </div>
    )
}

export default connect(null, { getLibros })(Home);