import React from 'react';
import s from "./Card.module.css";
import { useNavigate } from "react-router-dom";
import { CallToAction } from '../CallToAction/CallToAction';
import { toastSuccess } from "../../helpers/index";
import { useDispatch, connect } from 'react-redux';
import { updateCart } from '../../redux/actions';
import { capitalizeWords } from '../../helpers/index';
import { formatNumber } from '../../helpers/index';

function Card({product}) {
    // Tarjeta de producto

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCart = function(){
        
        let productsCart = [];

        if (localStorage.getItem('order')) {            // Si hay algo en el localStorage
            productsCart = localStorage.getItem('order');  // Lo traigo
            productsCart = JSON.parse(productsCart);       // Y lo convierto a JSON

            if (productsCart.filter((e) => e.id_libro === product.id_libro).length > 0){  // Si este producto ya existe en el carrito
                productsCart.forEach((p,i)=>{
                    if (p.id_libro === product.id_libro){
                        p.cant = Number(p.cant) + 1;          // Le sumo los amounts de esta card
                    }
                })
            } else {                                   //  Si no no existe en el carrito
                productsCart.push({...product, cant: 1});  //  Lo pusheo
            }

            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage

        } else {                                           // Si no hay nada en el localStorage
            productsCart.push({...product, cant: 1});    //  Lo pusheo
            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage
        }

        toastSuccess("Producto agregado al carrito");

        dispatch(updateCart());

    }

    return (
        <>
            <div className={s.container}>
                <img alt="Portada del Libro" src={`${process.env.REACT_APP_IMG_API}${product.imagen}`} onClick={()=>{Navigate(`/Detalle/${product.id_libro}`);}}/>
                <div className={s.gap}/>
                    <div className={s.data}>
                        <p className={s.titulo} onClick={()=>{Navigate(`/Detalle/${product.id_libro}`);}}>{capitalizeWords(product.titulo)}</p>
                        <div>
                        <p className={s.autor}>{capitalizeWords(product.autores)}</p>
                        {
                            product.precio === product.preciodescuento && (product.preciodescuento != "0.00" && product.precio !== "0.00") &&
                            <p className={s.precio}>{formatNumber(product.precio)}</p>
                        }
                        {
                            product.precio !== product.preciodescuento && (product.preciodescuento != "0.00" && product.precio !== "0.00") &&
                            <div>
                                <p className={s.precioDescuento}>{formatNumber(product.precio)}</p>
                                <p className={s.nuevoPrecio}>{formatNumber(product.preciodescuento)}</p>
                            </div>
                        }
                    </div>
                </div>
                {
                    product.preciodescuento == "0.00" || product.precio == "0.00" ?
                    <CallToAction content="Agregar al Carrito" disabledStyle={true}/>
                    :
                    <CallToAction content="Agregar al Carrito" onClick={()=>{handleCart();}}/>
                }
            </div>
        </>
    )
}

export default connect(null, { updateCart })(Card);