import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const handleWasap = function(e){
  e.preventDefault();
  window.open(`https://wa.me/+5491132640285`,'_blank');
}

export default function Layout({children}) {
  return (
    <div>
        <Navbar/>
        {children}

        {/*  Logo wasap */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png" 
          alt="Whatsapp Icon" 
          className="wppIcon" 
          onClick={handleWasap}
        />

        <Footer/>
    </div>
  )
}
