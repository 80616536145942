import React, { useEffect, useState } from 'react';
import s from "./CartCard.module.css";
import { DeleteOutline, Add, Remove } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { formatNumber } from '../../helpers';
import { capitalizeWords } from '../../helpers';
import useMediaQuery from '../../hooks/useMediaQuery';

export default function CartCard({product, deleteItem, calculateTotal, modalControl, disableCant}) {

    // Tarjetas del carrito

    const [unidades, setUnidades] = useState(product.cant);
    const Navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 415px)');

    useEffect(()=>{
        let productsCart = [];

        productsCart = localStorage.getItem('order');  // Lo traigo
        productsCart = JSON.parse(productsCart);       // Y lo convierto a JSON

        if (productsCart.filter((e) => e.id_libro === product.id_libro).length > 0){  // Si este producto ya existe en el carrito
            productsCart.forEach((p,i)=>{
                if (p.id_libro === product.id_libro){
                    p.cant = unidades;          // Le sumo los amounts de esta card
                }
            })
        } else {                                   //  Si no no existe en el carrito
            productsCart.push({...product, cant: unidades});  //  Lo pusheo
        }

        localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage

        calculateTotal();

    },[unidades]);

    useEffect(()=>{
        setUnidades(product.cant);
    },[product.cant])

    const handleUnidades = function(el){
        if (el.value !== "") {
            if (parseInt(el.value) < parseInt(el.min)) {
              el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
              el.value = el.max;
            }
        }
        
        setUnidades(el.value);
    }

    const handleEmptyInput = function(el){
        if(!el.value){
            setUnidades(1);
        }
    }

    const handleBtns = function(action){

        if(action === "REMOVE" && (unidades <= 1 || !unidades)) return;

        if(action === "ADD"){
            setUnidades(Number(unidades) + 1);
        }
        if(action === "REMOVE"){
            setUnidades(Number(unidades) - 1);
        }
    }

    const handleDelete = function(id_libro){
        deleteItem(id_libro);
    }

    return (
        <div className={isSmallScreen ? s.containerMobile : s.container}>
            <img alt="Titulo del libro" onClick={()=>{ modalControl && modalControl(false); Navigate(`/Detalle/${product.id_libro}`);}} src={`${process.env.REACT_APP_IMG_API}${product.imagen}`}/>
            <div className={isSmallScreen ? s.dataMobile : s.data}>
                <div className={s.titulosContainer}>
                    <div className={s.tituloWrapper}>
                        <p className={s.titulo} onClick={()=>{modalControl && modalControl(false); Navigate(`/Detalle/${product.id_libro}`);}}>{capitalizeWords(product.titulo)}</p>
                    </div>
                    <p className={s.autor}>{product.editorial.replace(/Ã±|Ñ/g, 'ñ')}</p>
                </div>
                <div className={isSmallScreen ? s.accionesContainerMobile : s.accionesContainer}>
                    {
                        disableCant ?
                        <div className={s.unidadesContainer}>
                            <Remove sx={{ fontSize: 20, color: "#BFBFBF"}}/>
                            <input type='number'  min="1" max="99" value={unidades} disabled/>
                            <Add sx={{ fontSize: 20, color: "#BFBFBF"}}/>
                        </div> :
                        <div className={s.unidadesContainer}>
                            <Remove sx={{ fontSize: 20, color: "#1C1B1F", cursor: "pointer"}} onClick={()=>{handleBtns("REMOVE");}}/>
                            <input type='number'  min="1" max="99" value={unidades} onBlur={(e)=>{handleEmptyInput(e.target)}} onChange={(e)=>{handleUnidades(e.target);}}/>
                            <Add sx={{ fontSize: 20, color: "#1C1B1F", cursor: "pointer"}} onClick={()=>{handleBtns("ADD");}}/>
                        </div>
                    }

                    {
                        product.precio === product.preciodescuento && 
                        <p className={s.precio}>{formatNumber(product.precio)}</p>
                    }
                    {
                        product.precio !== product.preciodescuento && 
                        <div>
                            <p className={s.precioDescuento}>{formatNumber(product.precio)}</p>
                            <p className={s.precio}>{formatNumber(product.preciodescuento)}</p>
                        </div>
                    }
                    {
                        disableCant ?
                        <DeleteOutline sx={{ fontSize: 26, color: "#BFBFBF"}}/> :
                        <DeleteOutline sx={{ fontSize: 26, color: "#1C1B1F", cursor: "pointer"}} onClick={()=>{handleDelete(product.id_libro);}}/>
                    }
                    
                </div>
            </div>
        </div>
    )
}
