import React, { useEffect, useState } from 'react';
import s from "./SelectSucursal.module.css";
import axios from "axios";
import { useSelector } from 'react-redux';

export default function SelectSucursal({handleSucursal}) {

    // Este componente trae la lista de sucursales disponibles para retiro, las lita en un select y ejecuta la funcion parametro handleSucursal cuando se hace submit.

    const [sucursales, setSucursales] = useState([]);
    const [selected, setSelected] = useState('default');

    const token = useSelector(state => state.token);

    useEffect(()=>{
        async function fetchData(){
    
            let promise;
            try{
                promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getsucursales.php?token=${token}&retiro_sucursal=1`);
            } catch(e){
                console.log(e);
            }
    
            if(!promise.data || !Array.isArray(promise.data)){
                setSucursales([]);
            } else {
                setSucursales(promise.data);
            }
        }

        fetchData();
    },[])

    const handleSelectorChange = function(e){
        setSelected({
            sucursal: sucursales[Number(e.target.value)].sucursal,
            id_sucursal: sucursales[Number(e.target.value)].id_sucursal,
            direccion: sucursales[Number(e.target.value)].direccion
        })
    }

  return (
    <div className={s.container}>
        <h2>Seleccione una sucursal</h2>
        <p>Sede</p>
        <select onChange={handleSelectorChange}>
            <option value='default'>Seleccionar</option>
            {
                sucursales?.map((sucursal, index)=>{
                    return <option key={index} value={index}>{`${sucursal.sucursal} - ${sucursal.direccion}`}</option>
                })
            }
        </select>
        <button onClick={(e)=>{e.preventDefault(); if(selected !== 'default') { handleSucursal(selected) }}}>Seleccionar Sucursal</button>
    </div>
  )
}
